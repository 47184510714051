/*
The current aggregated state of a question.

This isn't the ideal structure; ideally, there would be no distinction between a "Question"
and a "Query", and we wouldn't have a top-level collection of Questions. Instead, because queries
should be shared/cached across all questions, we'd just have a collection of queries.
 */
import { Passage } from "../../ul/ul-element";
import {
  DEFAULT_MAX_ALLOWED_DEBUG_MESSAGES,
  DebugMessageQueryStart,
  LlSolution,
  ResponseDebugMessage,
  ResponseHeader,
  ResponseTopLevelAnswer,
  SolveResponse,
  SolverInfo,
  toUlElement,
} from "../reasoning-engine";
import { getNowUtc, updateQuestionState } from "./index";
import { QueryState } from "./query";
import { SolverNodeState } from "./solverNode";
import {
  StatusCancelled,
  StatusCompleted,
  StatusInternalError,
  StatusNetworkError,
  StatusRunning,
} from "./status";

export type QuestionState = {
  // The id of the question as a whole. Because IsItTrueThat is handled differently to What,
  // this id doesn't correspond to any key in `queryStates`; instead, the RE backend synthesises
  // a random id here. If we make solvers return Answers, this will be a valid key.
  questionId: string;
  // The timestamp that this query was started at.
  queryStartTime: number;
  // The user who requested this query
  user: string;
  // Whether debug mode was enabled for this query
  debugMode: boolean;
  // For What questions there is one item in this list. For IsItTrueThat, there are two.
  // If we make solvers return Answers, we can remove this.
  topLevelQueryIds: string[];
  // The top-level question that was asked
  query: Passage;
  // Information about the solvers that were used, keyed by solverId.
  solverInfo: Map<number, SolverInfo>;
  // The "top level" (direct) answers to the `query`. These currently have a different structure
  // to the other `SolutionWithIds` because of the whole "Answer vs mapping" thing.
  topLevelAnswers: ResponseTopLevelAnswer[];
  // SolutionIds of all the topLevelAnswers that have later been invalidated
  invalidatedTopLevelAnswerIds: string[];
  // Mapping from solutionId to SolutionWithIds for all solutions both top level and intermediate.
  // The `SolutionWithIds` objects contain `inputSolutionIds` and `operation` (which needs
  // fleshing out in the RE `Explanation` classes) to build the explanation graph for each solution.
  // Note, in the case that a solution was retrieved from the cache, multiple solutions may have the
  // same SolutionId (hence the list); You can distinguish between the solutions as they will have
  // the same executingSolverNodeId but different solverNodeIds.
  allSolutions: Map<string, LlSolution[]>;
  // Mapping from queryId to the current state of that query (including the top-level query).
  queryStates: Map<string, QueryState>;
  // Mapping from solverNodeId to the state of the "solver node". A solver node is a unique
  // invocation of a solver, i.e. a unique (solver, passage) pair.
  solverNodeStates: Map<string, SolverNodeState>;
  // The number of all responses that have been read (and used) to aggregate together this
  // `QuestionState`.
  numDebugMessages: number;
  // The overall status of the question.
  status: QuestionStatus;
  // A function to cancel the backend call.
  cancelFunc: () => void;
  // The current number of debug messages allowed to contribute to this instance
  maximumNumDebugMessages: number;
  // The number of debug messages received from the backend that were discarded as
  // maximumNumDebugMessages was reached
  numDiscardedDebugMessages: number;
};

export const questionStateFromLogs = (
  debugMessages: SolveResponse[],
  limit?: number
): QuestionState | undefined => {
  const header = debugMessages.find(
    (message) => message["@type"] === "HEADER"
  ) as ResponseHeader;
  if (debugMessages.length > 1 && header) {
    const topQueryStart = debugMessages.find(
      (message) =>
        message["@type"] === "DEBUG_MESSAGE" &&
        message.message["@type"] === "STATUS_QUERY_START" &&
        message.message.parentSolverNodeId === null // root level query
    );

    if (topQueryStart) {
      const queryMessage = (topQueryStart as ResponseDebugMessage)
        .message as DebugMessageQueryStart;

      const questionState: QuestionState = {
        questionId: header.questionId,
        queryStartTime: queryMessage.timestamp,
        user: "unspecified",
        debugMode: true,
        topLevelQueryIds: [],
        query: toUlElement(queryMessage.queryAsQuestion) as Passage,
        solverInfo: new Map(),
        topLevelAnswers: [],
        invalidatedTopLevelAnswerIds: [],
        allSolutions: new Map(),
        queryStates: new Map(),
        solverNodeStates: new Map(),
        numDebugMessages: 0, // Excluding header
        status: {
          "@type": "COMPLETED",
          startedAt: getNowUtc(),
          endedAt: getNowUtc(),
        },
        cancelFunc: () => {},
        maximumNumDebugMessages: limit
          ? limit
          : DEFAULT_MAX_ALLOWED_DEBUG_MESSAGES,
        numDiscardedDebugMessages: 0,
      };

      let i = 1;
      while (
        i < debugMessages.length &&
        (!limit || questionState.numDebugMessages < limit)
      ) {
        updateQuestionState(questionState, debugMessages[i++], true);
      }
      return questionState;
    }
  }
};

export type QuestionStatus =
  | StatusRunning
  | StatusCompleted
  | StatusCancelled
  | StatusInternalError
  | StatusNetworkError;
