import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { Input } from "antd";
import { Modal, ModalProps, useModal } from "@unlikelyai-magic/ui/modals";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { spacings } from "@unlikelyai-magic/ui/variables";

const Content = styled(Modal.Content)`
  gap: ${spacings.sm};
`;

type TitleModalProps = ModalProps & {
  onConfirm: () => void;
  form: any;
};

export const TitleModal = styled(
  ({ onConfirm, className, form }: TitleModalProps) => {
    const { closeModal } = useModal();

    const handleConfirm = () => {
      onConfirm();
      closeModal();
    };

    return (
      <Modal className={className}>
        <Content>
          <Paragraph>Name</Paragraph>
          <Input
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </Content>
        <Modal.Footer>
          <SecondaryButton onClick={closeModal}>Go back</SecondaryButton>
          <PrimaryButton onClick={handleConfirm}>Create</PrimaryButton>
        </Modal.Footer>
      </Modal>
    );
  }
)`
  width: 35rem;
  border-radius: 0.75rem;

  & > *:not(:first-child) {
    border-top: none;
  }

  & ${Content}, ${Modal.Footer} {
    padding: ${spacings.xl};
  }

  ${Modal.Footer} {
    gap: ${spacings.xl};
    padding-top: 0;

    & > * {
      flex: 1;
    }
  }
`;
