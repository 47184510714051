import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { contexts } from "@dip/contexts";
import { Domain } from "@dip/data-access/api-types";
import { useDeleteDomainMutation } from "@dip/data-access/dip-api-service";
import { ActionsMenu } from "@dip/ui/components/actions-menu";
import { Popover } from "@dip/ui/components/popover";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { FadingPathLoadingIcon, Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { ConfirmationModal, useModal } from "@unlikelyai-magic/ui/modals";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const RowWrapper = styled(Spacing)`
  position: relative;
  border-bottom: 0.05rem solid
    ${({ theme }) => theme.colors.component.border[1]};
  padding: 1rem 1.5rem;

  &:hover {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: ${({ theme }) => theme.colors.component.background[1]};
    cursor: pointer;
  }
`;

const LoadingSpinner = styled(FadingPathLoadingIcon)`
  color: ${({ theme }) => theme.colors.action.primary.default};
`;

const PopoverWrapper = styled(Popover)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const PopoverIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text.dark.default};
  fill: ${({ theme }) => theme.colors.semantic.error.default};
`;

const SecondaryText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
`;

const ActionsMenuWrapper = styled(ActionsMenu)`
  position: absolute;
  right: 1rem;
`;

export type PolicyWithStates = Domain & {
  loading: boolean;
  trainingScenariosFailing: boolean;
  understandingError: boolean;
};

interface PolicyRowProps {
  policy: PolicyWithStates;
}

export const PolicyRow = ({ policy }: PolicyRowProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [deleteDomain] = useDeleteDomainMutation();

  const [isHovered, setIsHovered] = useState(false);

  const {
    id,
    loading,
    trainingScenariosFailing,
    understandingError,
    title,
    context,
    dateCreated,
  } = policy;

  return (
    <RowWrapper
      direction="horizontal"
      justify="space-between"
      items="center"
      onClick={() => navigate(`/domains/${id}`)}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Spacing direction="horizontal" items="center" gap="2xs">
        {loading && <LoadingSpinner size="1.25rem" />}
        {trainingScenariosFailing ||
          (understandingError && (
            <PopoverWrapper
              content={
                trainingScenariosFailing
                  ? "Training examples not evaluated properly"
                  : "Policy has not been understood"
              }
              placement="bottom"
            >
              <PopoverIcon icon={ExclamationCircleIcon} size="1.4rem" />
            </PopoverWrapper>
          ))}
        <Paragraph small>{title}</Paragraph>
      </Spacing>
      <SecondaryText small>
        {`${contexts[context].title}  •  Updated ${moment(
          new Date(dateCreated)
        ).fromNow()}`}
      </SecondaryText>
      {isHovered && (
        <ActionsMenuWrapper
          actions={[
            {
              text: "Delete",
              onClick: (e) => {
                e.domEvent.stopPropagation();
                openModal(
                  <ConfirmationModal
                    description="This will permanently delete this domain."
                    onConfirm={() => {
                      deleteDomain({ id });
                      closeModal();
                    }}
                  />
                );
              },
              danger: true,
            },
          ]}
        />
      )}
    </RowWrapper>
  );
};
