import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const LOCAL_STORAGE_SESSION_KEY = "session_token";

export type AuthTokenResponse = {
  id_token: string;
  access_token: string;
  refresh_token: string;
  error: string;
};

export interface ITokenContext {
  token: string;
  setToken: (value: string) => void;
}

export const CognitoTokenContext = createContext<ITokenContext>({
  token: "",
  setToken: () => console.warn("no token provider"),
});

export const useCognitoToken = () => {
  const value = useContext<ITokenContext>(CognitoTokenContext);
  if (!value)
    throw new Error(
      "useCognitoToken must be used within a CognitoTokenContext"
    );
  return value;
};

type CognitoTokenContextProviderProps = {
  cognitoCliendId: string;
  cognitoRedirectUrl: string;
  cognitoDomain: string;
  onTokenMissing?: () => void;
  onTokenExpired?: () => void;
  unauthorisedPage?: ReactNode;
  enabled: boolean;
  children?: ReactNode | ReactNode[];
};

export const CognitoTokenContextProvider = ({
  cognitoCliendId,
  cognitoRedirectUrl,
  cognitoDomain,
  unauthorisedPage,
  onTokenMissing,
  onTokenExpired,
  enabled,
  children,
}: CognitoTokenContextProviderProps) => {
  const [params] = useSearchParams();
  const [token, setToken] = useState<string>(
    window.localStorage.getItem(LOCAL_STORAGE_SESSION_KEY) ||
      (enabled ? "" : "token")
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const code = params.get("code");
    if (!code) {
      // If there is no code and no token, redirect to the login page
      if (!token) onTokenMissing && onTokenMissing();
      return;
    }

    const tokenRequestParams = new URLSearchParams();
    tokenRequestParams.set("code", params.get("code") as string);
    tokenRequestParams.set("client_id", cognitoCliendId);
    tokenRequestParams.set("grant_type", "authorization_code");
    tokenRequestParams.set("redirect_uri", cognitoRedirectUrl);
    fetch(cognitoDomain + "/oauth2/token?" + tokenRequestParams.toString(), {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((result) => result.json())
      .then((resp) => {
        if (!(resp as AuthTokenResponse).error) {
          setToken((resp as AuthTokenResponse).id_token);

          // Remove the code from the URL
          const url = new URL(window.location.href);
          url.searchParams.delete("code");
          window.history.pushState(null, "", url.toString());
        }
      });
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!token) {
      window.localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
      return;
    }
    window.localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, token);
  }, [token || !enabled]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!token) return;
    const { exp } = jwtDecode(token);
    if (!exp) return;
    // Check if the token has not expired
    if (Date.now() < exp * 1000) {
      return;
    }

    // Clear the token from state and localstorage
    setToken("");
    window.localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);

    // Call the onTokenExpired callback if it exists
    onTokenExpired && onTokenExpired();
  }, [onTokenExpired || !enabled, token || !enabled]);

  return (
    <CognitoTokenContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {token ? children : unauthorisedPage}
    </CognitoTokenContext.Provider>
  );
};
