import { useState } from "react";
import {
  Message,
  PipelineEvent,
  RealtimeChannel,
  useChannel,
} from "@dip/data-access/dip-api-service";
import { UnlikelyAISpinner } from "@dip/ui/components/spinners";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const CardContainer = styled(Spacing)`
  background-color: ${({ theme }) => theme.colors.component.background.dark[1]};
  height: 20rem;
  border-radius: ${({ theme }) => theme.roundness.md};
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div<{ size: number }>`
  height: ${({ size }) => size}%;
  width: ${({ size }) => size}%;
`;

type UnderstandingLoadingCardProps = { domainUnderstandingId: string };

export const UnderstandingLoadingCard = ({
  domainUnderstandingId,
}: UnderstandingLoadingCardProps) => {
  const theme = useTheme();
  const defaultStepName = "Understanding";
  const [step, setStep] = useState(defaultStepName);
  const [refinements, setRefinements] = useState([0, 0]);

  useChannel(
    `${RealtimeChannel.PIPELINE}:${domainUnderstandingId}`,
    ({ name, data }: Message<PipelineEvent>) => {
      setStep(name ?? defaultStepName);
      setRefinements([data?.refinement ?? 0, data?.maxRefinements ?? 0]);
    }
  );

  return (
    <CardContainer>
      <SpinnerContainer size={20}>
        <UnlikelyAISpinner />
      </SpinnerContainer>
      <Paragraph
        color={theme.colors.text.dark.default}
      >{`${step}...`}</Paragraph>
      <Spacing direction="horizontal" gap="sm">
        <Paragraph color={theme.colors.text.secondary.default} small>
          Refinement
        </Paragraph>
        <Paragraph color={theme.colors.text.dark.default} small>
          {`${refinements[0]} / ${refinements[1]}`}
        </Paragraph>
      </Spacing>
    </CardContainer>
  );
};
