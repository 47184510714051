import React, { FunctionComponent } from "react";
import { Typography } from "antd";
import { UlSerializer } from "../ul/UlSerializer";
import { Explanation, extractExplanationDetails } from "../ul/explanation";
import { UlElement } from "../ul/ul-element";

const { Text } = Typography;

type ExplanationProps = {
  explanation: Explanation;
};

export const DisplayedExplanation: FunctionComponent<ExplanationProps> = (
  props
) => {
  const displaySerialised = (elem: UlElement) => {
    return (
      <li>
        <UlSerializer element={elem} truncateUuids={true}>
          {(s) => <Text code>{s}</Text>}
        </UlSerializer>
      </li>
    );
  };

  /**
   * Most explanation types have children. We want to build a tree where such explanations are
   * represented as the passage that's being explained, then, as children, one or more passages that
   * describe the particular explanation. For example, a reasoning explanation has the reasoning
   * passage. A semantic equivalence explanation has the equivalence passages used. Finally, as
   * children of the final passage describing the explanation, the child explanation steps
   * (potentially several for reasoning, always only one for semantic equivalence).
   *
   * Reasoning example:
   * <pre>
   * (IsA Camembert (ClassPlusAttribute Cheese (CombinedAttribute Creamy French)))
   *   (MeansThat (And (IsA X C) (HasAttribute X A)) (IsA X (ClassPlusAttribute C A)))
   *     (HasAttribute Camembert (CombinedAttribute Creamy French))
   *     (IsA Camembert Cheese)
   * </pre>
   */
  return extractExplanationDetails(
    props.explanation,
    (passage, details, children) => {
      return (
        <>
          {/* Main passage being explained. */}
          {displaySerialised(passage)}
          <ul>
            {details.length > 0 && (
              /* One or more detail items (reasoning passage, semantic equivalence passages */
              <li>
                {details.map((item) => displaySerialised(item))}
                <ul>
                  {/* Child explanation steps */}
                  {children.map((child, index) => (
                    <DisplayedExplanation explanation={child} key={index} />
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </>
      );
    }
  );
};
