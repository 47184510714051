import { RequestHandler, setupWorker } from "msw";
import { MockApi } from "./MockApi";

/**
 * A worker which intercepts HTTP requests and use mock handlers to return data. This enables
 * frontend development without a working backend.
 * @see https://v1.mswjs.io/docs/getting-started/integrate/browser#configure-worker
 */
export const createMockServer = (...apis: MockApi[]) => {
  const handlers: RequestHandler[] = apis.map((api) => api.handlers).flat();
  const worker = setupWorker(...handlers);
  return {
    listen: () => worker.start(),
    stop: () => worker.stop(),
    printHandlers: () => worker.printHandlers(),
  };
};
