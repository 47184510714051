import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";

type ChatAvatarProps = ComponentProps<"div"> & {
  visible?: boolean;
};

export const BotAvatar = styled(({ visible, ...props }: ChatAvatarProps) => (
  <div {...props}>
    <Icon icon={UnlikelyAiLogo} size="1.25rem" />
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.component.border[1]};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  padding-top: 0.125rem;
`;
