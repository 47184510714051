import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SourceInformation, UNLIKELY_AI_SOURCE_URL } from "../reasoning";
import { Passage } from "../ul/ul-element";

export type PassageSourceContextProps = {
  children: React.ReactNode;
};

export type PassageSourceInformationData = {
  // TODO: see if we can use the passage UUID as the key instead of stringifying the passage
  passageToSources: Map<string, SourceInformation[]>;
  notifyUnknownPassageSource: (passage: Passage) => void;
};

export const PassageSourceInformationDataContext =
  React.createContext<PassageSourceInformationData>({
    passageToSources: new Map(),
    notifyUnknownPassageSource: () => {},
  });
export const usePassageSourceData = (
  getSourcesForPassage: (ulPassage: Passage) => Promise<SourceInformation[]>
) => {
  const [processUnknownPassageSourceArgs, setProcessUnknownPassageSourceArgs] =
    useState<Passage[] | undefined>();
  /**
   * Notify the provider that the source of a passage is unknown and should be fetched in the
   * background. Sets args that will get processed in a useEffect to kick off a fetch
   * @param passage The passage to fetch source information for
   */
  const notifyUnknownPassageSource = (passage: Passage) => {
    setProcessUnknownPassageSourceArgs((prevState) =>
      prevState ? [...prevState, passage] : [passage]
    );
  };

  const [passageSourceData, setPassageSourceData] =
    useState<PassageSourceInformationData>({
      passageToSources: new Map(),
      notifyUnknownPassageSource,
    });
  const [processedPassages, setProcessedPassages] = useState<Set<Passage>>(
    new Set()
  );

  /**
   * Process getting the source of a passage that we don't have a source for yet, filling the
   * passageToSources map with the result
   * @param passage Passage to process
   */
  const processUnknownPassageSource = useCallback(
    async (passage: Passage) => {
      // If we've already processed this passage, or more importantly are currently  processing this
      // passage, don't do it again
      if (processedPassages.has(passage)) {
        return;
      }
      setProcessedPassages((prevState) => new Set([...prevState, passage]));
      // Call endpoint to get sources for the passage
      try {
        const sources = await getSourcesForPassage(passage);
        const filteredSources = sources.filter(
          (source) => source.url !== UNLIKELY_AI_SOURCE_URL
        );
        setPassageSourceData((prevState) => ({
          ...prevState,
          passageToSources: new Map(prevState.passageToSources).set(
            JSON.stringify(passage),
            filteredSources
          ),
        }));

        setProcessUnknownPassageSourceArgs((prevState) =>
          prevState
            ? prevState.filter(
                (processedPassage) =>
                  JSON.stringify(processedPassage) !== JSON.stringify(passage)
              )
            : undefined
        );
      } catch (e) {
        // If we encounter an error, remove the passage from the processed set so that it can get
        // processed again
        console.error("Error fetching sources for passage", e);
        setProcessedPassages(
          (prevState) =>
            new Set(
              [...prevState].filter(
                (processedPassage) => processedPassage !== passage
              )
            )
        );
      }
    },
    [getSourcesForPassage, processedPassages]
  );

  useEffect(() => {
    if (processUnknownPassageSourceArgs) {
      processUnknownPassageSourceArgs.forEach(processUnknownPassageSource);
    }
  }, [processUnknownPassageSource, processUnknownPassageSourceArgs]);

  return {
    passageSourceData,
    notifyUnknownPassageSource,
  };
};

export const PassageSourceInformationProvider: FunctionComponent<
  PassageSourceContextProps
> = (props) => {
  // Default to no-op
  // TODO: move the endpoint to get passage source information somewhere central and use it here,
  //  then have the brian endpoint act as a pass-through to that one
  const { passageSourceData } = usePassageSourceData(() => Promise.resolve([]));

  return (
    <PassageSourceInformationDataContext.Provider value={passageSourceData}>
      {props.children}
    </PassageSourceInformationDataContext.Provider>
  );
};
