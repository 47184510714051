import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from "react";

export type SettingsSidebarState = {
  numOfRefinements: number;
  setNumOfRefinements: Dispatch<SetStateAction<number>>;
  showAnsweringUL: boolean;
  setShowAnsweringUL: Dispatch<SetStateAction<boolean>>;
};

export const SettingsSidebarContext = createContext<SettingsSidebarState>(
  {} as SettingsSidebarState
);

export const SettingsSidebarProvider = ({ children }: PropsWithChildren) => {
  const [numOfRefinements, setNumOfRefinements] = useState(5);
  const [showAnsweringUL, setShowAnsweringUL] = useState(false);
  return (
    <SettingsSidebarContext.Provider
      value={{
        numOfRefinements,
        setNumOfRefinements,
        showAnsweringUL,
        setShowAnsweringUL,
      }}
    >
      {children}
    </SettingsSidebarContext.Provider>
  );
};
