import {
  CreateDomainRequest,
  CreateDomainResponse,
  DeleteDomainRequest,
  DeleteDomainResponse,
  GetDomainRequest,
  GetDomainResponse,
  GetDomainsRequest,
  GetDomainsResponse,
} from "@dip/data-access/api-types";
import qs from "qs";
import { dipApi } from "./dipApi";

export const {
  useGetDomainQuery,
  useGetDomainsQuery,
  useCreateDomainMutation,
  useDeleteDomainMutation,
} = dipApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomain: builder.query<GetDomainResponse, GetDomainRequest>({
      query: ({ id }) => `/domains/${id}`,
    }),
    getDomains: builder.query<GetDomainsResponse, GetDomainsRequest>({
      query: (params) => {
        return `/domains?${qs.stringify(params, { skipNulls: true })}`;
      },
      providesTags: ["Domains"],
    }),
    createDomain: builder.mutation<CreateDomainResponse, CreateDomainRequest>({
      query: (body) => ({
        url: "/domains",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Domains"],
    }),
    deleteDomain: builder.mutation<DeleteDomainResponse, DeleteDomainRequest>({
      query: ({ id }) => ({
        url: `/domains/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Domains"],
    }),
  }),
});
