import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  ReactNode,
  useState,
} from "react";
import TextareaAutosize from "react-textarea-autosize";
import { TextareaAutosizeProps } from "react-textarea-autosize/dist/declarations/src";
import styled from "@emotion/styled";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { TransparentButton } from "@unlikelyai-magic/ui/buttons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { PromptInputHeader } from "./PromptInputHeader";

const SendButton = styled(TransparentButton)<{ disabled?: boolean }>`
  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    opacity: ${({ disabled }) => (disabled ? 20 : 100)}%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 2.5rem;
  padding-left: 1rem;
`;

const PromptTextArea = styled(TextareaAutosize)`
  flex: 1;
  color: ${({ theme }) => theme.colors.text.primary.default};
  line-height: 1.5rem;
  background: transparent;
  resize: none;

  ::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text.secondary.default};
  }

  &,
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export type PromptInputProps = Pick<
  TextareaAutosizeProps,
  "className" | "placeholder" | "maxRows" | "minRows"
> & {
  onSubmit: (text: string) => void;
  initialValue?: string;
  header?: ReactNode;
};

export const PromptInput = styled(
  ({
    placeholder = "Type something...",
    initialValue = "",
    onSubmit,
    header,
    maxRows = 5,
    ...props
  }: PromptInputProps) => {
    const [value, setValue] = useState<string>(initialValue);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
    };

    const handleSubmit = async (event: FormEvent) => {
      event.preventDefault();
      if (!value.trim()) return;
      onSubmit(value);
      setValue("");
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        handleSubmit(event);
      }
    };

    return (
      <form onSubmit={handleSubmit} {...props}>
        {header && <PromptInputHeader>{header}</PromptInputHeader>}
        <Spacing gap="3xs" direction="horizontal" items="flex-end">
          <InputWrapper>
            <PromptTextArea
              maxRows={maxRows}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </InputWrapper>
          <SendButton
            icon={<PaperAirplaneIcon />}
            disabled={!value}
            onClick={handleSubmit}
          />
        </Spacing>
      </form>
    );
  }
)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.sm};
  gap: ${({ theme }) => theme.spacings.sm};
`;
