import React, { FunctionComponent } from "react";
import { Typography } from "antd";
import { UlSerializer } from "../ul/UlSerializer";
import { Explanation } from "../ul/explanation";
import { UlElement } from "../ul/ul-element";

const { Text } = Typography;

type UlExplanationSummaryProps = {
  explanation: Explanation;
};

export const UlExplanationSummary: FunctionComponent<
  UlExplanationSummaryProps
> = (props) => {
  const displaySerialised = (elem: UlElement) => {
    return (
      <UlSerializer element={elem} truncateUuids={true}>
        {(s) => <Text code>{s}</Text>}
      </UlSerializer>
    );
  };

  if (props.explanation.explanationType === "COMPUTATION") {
    return displaySerialised(props.explanation.computationUnitUsed);
  } else {
    return displaySerialised(props.explanation.passage);
  }
};
