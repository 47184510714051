import { ComponentProps, ComponentType } from "react";
import { theme } from "@dip/theme";
import { Spinner } from "@dip/ui/components/spinners";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  MinusIcon,
} from "@heroicons/react/24/solid";

export enum Status {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DISABLED = "disabled",
  LOADING = "loading",
}

export const defaultStatusConfig: Record<
  Status,
  {
    icon: ComponentType<ComponentProps<"svg">>;
    color: string;
    text: string;
    backgroundColor: string;
  }
> = {
  [Status.SUCCESS]: {
    icon: CheckCircleIcon,
    color: theme.colors.semantic.success.default,
    backgroundColor: theme.colors.semantic.success.background,
    text: "Success",
  },
  [Status.WARNING]: {
    icon: ExclamationCircleIcon,
    color: theme.colors.semantic.warning.default,
    backgroundColor: theme.colors.semantic.warning.background,
    text: "Warning",
  },
  [Status.ERROR]: {
    icon: ExclamationCircleIcon,
    color: theme.colors.semantic.error.default,
    backgroundColor: theme.colors.semantic.error.background,
    text: "Error",
  },
  [Status.INFO]: {
    icon: InformationCircleIcon,
    color: theme.colors.action.link.default,
    backgroundColor: theme.colors.background.default,
    text: "Info",
  },
  [Status.DISABLED]: {
    icon: MinusIcon,
    color: theme.colors.action.primary.inactive,
    backgroundColor: theme.colors.background.default,
    text: "Disabled",
  },
  [Status.LOADING]: {
    icon: Spinner,
    color: theme.colors.action.link.default,
    backgroundColor: theme.colors.background.default,
    text: "Loading",
  },
};
