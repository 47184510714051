import { v4 as uuidv4, validate } from "uuid";
import { Optional, toOptional } from "../Optional";
import { Explanation } from "./explanation";

export type UlElement = string | Uuid | Passage;

export type PassageMetadata = {
  explanation: Optional<Explanation>;
  dependentOn: Optional<Passage[]>;
  hasUndergonePreReasoning: Optional<boolean>;
  preReasoningRefreshTime: Optional<number>;
  lastUpdatedByPreReasoningTime: Optional<number>;
  hasBeenReconceptualised: Optional<boolean>;
  reconceptualisationCompletionTime: Optional<number>;
  numberOfPassagesReconceptualised: Optional<number>;
  storeUri: Optional<string>;
  author: Optional<string>;
  createTime: Optional<number>;
  lastUpdateTime: Optional<number>;
  isIgnored: Optional<boolean>;
  tags: Optional<string[]>;
  reasoningPassageLookupCountData: Optional<number>;
  isInferredKnowledge: Optional<boolean>;
  sourceUuid: Optional<Uuid>;
  isPreDerived: Optional<boolean>;
};

export function metadata({
  explanation,
  dependentOn,
  hasUndergonePreReasoning,
  preReasoningRefreshTime,
  lastUpdatedByPreReasoningTime,
  hasBeenReconceptualised,
  reconceptualisationCompletionTime,
  numberOfPassagesReconceptualised,
  storeUri,
  author,
  createTime,
  lastUpdateTime,
  isIgnored,
  tags,
  reasoningPassageLookupCountData,
  isInferredKnowledge,
  sourceUuid,
  isPreDerived,
}: {
  explanation?: Explanation;
  dependentOn?: Passage[];
  hasUndergonePreReasoning?: boolean;
  preReasoningRefreshTime?: number;
  lastUpdatedByPreReasoningTime?: number;
  hasBeenReconceptualised?: boolean;
  reconceptualisationCompletionTime?: number;
  numberOfPassagesReconceptualised?: number;
  storeUri?: string;
  author?: string;
  createTime?: number;
  lastUpdateTime?: number;
  isIgnored?: boolean;
  tags?: string[];
  reasoningPassageLookupCountData?: number;
  isInferredKnowledge?: boolean;
  sourceUuid?: Uuid;
  isPreDerived?: boolean;
}): PassageMetadata {
  return {
    explanation: toOptional(explanation),
    dependentOn: toOptional(dependentOn),
    hasUndergonePreReasoning: toOptional(hasUndergonePreReasoning),
    preReasoningRefreshTime: toOptional(preReasoningRefreshTime),
    lastUpdatedByPreReasoningTime: toOptional(lastUpdatedByPreReasoningTime),
    hasBeenReconceptualised: toOptional(hasBeenReconceptualised),
    reconceptualisationCompletionTime: toOptional(
      reconceptualisationCompletionTime
    ),
    numberOfPassagesReconceptualised: toOptional(
      numberOfPassagesReconceptualised
    ),
    storeUri: toOptional(storeUri),
    author: toOptional(author),
    createTime: toOptional(createTime),
    lastUpdateTime: toOptional(lastUpdateTime),
    isIgnored: toOptional(isIgnored),
    tags: toOptional(tags),
    reasoningPassageLookupCountData: toOptional(
      reasoningPassageLookupCountData
    ),
    isInferredKnowledge: toOptional(isInferredKnowledge),
    sourceUuid: toOptional(sourceUuid),
    isPreDerived: toOptional(isPreDerived),
  };
}

export type Passage = {
  // Although the UUID still appears here, it should not in general be shown to the user,
  // because it is an implementation detail. It is sometimes useful for debugging however,
  // and hence is available in the PassageDetails. It also may not be there depending on whether
  // this was a generated passage (e.g. generated by Translation) or whether it was read out of the
  // database.
  // TODO(PLAT-1707): Improve this handling as it is confusing that you don't know when passages do
  //   and don't have a UUID attached
  uuid?: string;
  elements: UlElement[];
  metadata?: PassageMetadata;
};

// Adds metadata to a passage. If the passage already has metadata, then it is overwritten
export function withMetadata(
  passage: Passage,
  metadata: PassageMetadata
): Passage {
  return {
    ...passage,
    metadata,
  };
}

export type Uuid = {
  uuid: string;
};

export function isValidUlElement(data: any): data is UlElement {
  if (typeof data === "string") return true;
  return isValidUuid(data) || isValidPassage(data);
}

function isValidUuid(data: any): data is UlElement {
  return (
    typeof data === "object" &&
    data !== null &&
    typeof data.uuid === "string" &&
    validate(data.uuid as string)
  );
}

export function isValidPassage(data: any): data is UlElement {
  return (
    typeof data === "object" &&
    data !== null &&
    Array.isArray(data.elements) &&
    (data.elements as any[]).every((item) => isValidUlElement(item))
  );
}

export function emptyPassage(): Passage {
  return {
    uuid: uuidv4(),
    elements: [],
  };
}

export function isUlString(ulElement: UlElement): ulElement is string {
  return typeof ulElement === "string";
}

export function isUlUuid(ulElement: UlElement): ulElement is Uuid {
  return (
    typeof ulElement !== "string" &&
    typeof ulElement !== "undefined" &&
    (ulElement as Passage).elements === undefined
  );
}

export function isUlPassage(ulElement: UlElement): ulElement is Passage {
  return (
    typeof ulElement !== "string" &&
    (ulElement as Passage).elements !== undefined
  );
}

export function gatherNodes(ulElement: UlElement): Uuid[] {
  if (isUlUuid(ulElement)) return [ulElement];

  if (isUlPassage(ulElement)) {
    return Array.from(new Set(ulElement.elements.flatMap(gatherNodes)));
  }

  return [];
}

export function contentEqual(elem1: UlElement, elem2: UlElement): boolean {
  if (isUlUuid(elem1) && isUlUuid(elem2)) {
    return elem1.uuid === elem2.uuid;
  } else if (isUlString(elem1) && isUlString(elem2)) {
    return elem1 === elem2;
  } else if (isUlPassage(elem1) && isUlPassage(elem2)) {
    return (
      elem1.elements.length === elem2.elements.length &&
      elem1.elements.every((elem, index) =>
        contentEqual(elem2.elements[index], elem)
      )
    );
  } else {
    return false;
  }
}
