import { Outlet, useNavigate, useParams } from "react-router-dom";
import { FeedbackRequestedFrom } from "@dip/data-access/api-types";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { PageSpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { DomainStatusMenu } from "../components";
import { DomainStatus, ScenarioStatus } from "../constants";

const UnderstandingContainer = styled(Spacing)`
  height: 100%;
`;

const Sidebar = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.background.default};
  width: 20rem;
  flex-shrink: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.component.border[1]};

  @media (min-width: ${screenBreakpoints.sm}) {
    display: flex;
  }
`;

export const UnderstandingLayout = () => {
  const { domainId, scenarioId } = useParams();
  const navigate = useNavigate();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  const setScenario = (scenarioId?: string) => {
    scenarioId
      ? navigate(`/domains/${domainId}/understanding/scenarios/${scenarioId}`, {
          replace: true,
        })
      : navigate(`/domains/${domainId}/understanding`, { replace: true });
  };

  if (!data) return <PageSpinner />;

  const versions = data.understandings[0].versions;
  const latestVersion = versions?.[versions.length - 1];
  const stillUnderstandingDomain =
    latestVersion === null || latestVersion === undefined;
  const domainNotUnderstood =
    latestVersion?.feedbackRequestedFrom === FeedbackRequestedFrom.HUMAN;

  const scenarios = data.trainingScenarios.map(({ id, solution }, index) => {
    const getStatus = () => {
      const latestScenarioAnswer =
        latestVersion?.trainingScenarioDecisions.find(
          (decision) => decision.trainingScenarioId === id
        )?.decision.result;
      const stillRunningScenarios =
        latestScenarioAnswer === null || latestScenarioAnswer === undefined;
      switch (true) {
        case stillUnderstandingDomain || domainNotUnderstood:
          return ScenarioStatus.DISABLED;
        case stillRunningScenarios:
          return ScenarioStatus.RUNNING;
        case latestScenarioAnswer === solution.answer:
          return ScenarioStatus.SUCCESS;
        default:
          return ScenarioStatus.WRONG_ANSWER;
      }
    };
    return {
      id: id ?? "",
      name: "Scenario " + (index + 1),
      status: getStatus(),
    };
  });

  const getDomainStatus = () => {
    if (stillUnderstandingDomain) return DomainStatus.UNDERSTANDING_DOMAIN;
    if (domainNotUnderstood) return DomainStatus.NOT_UNDERSTOOD;
    if (
      scenarios.some((scenario) => scenario.status === ScenarioStatus.RUNNING)
    )
      return DomainStatus.RUNNING_SCENARIOS;
    if (
      scenarios.some(
        (scenario) => scenario.status === ScenarioStatus.WRONG_ANSWER
      )
    )
      return DomainStatus.SCENARIOS_FAILING;
    return DomainStatus.SUCCESS;
  };

  return (
    <UnderstandingContainer direction="horizontal" gap="none">
      <Sidebar>
        <DomainStatusMenu
          domainStatus={getDomainStatus()}
          scenarios={scenarios}
          currentScenario={scenarioId}
          setScenario={setScenario}
        />
      </Sidebar>
      <Outlet />
    </UnderstandingContainer>
  );
};
