import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { NicknameDomain } from "./nickname-domain";

const NicknameDomainContext = React.createContext<NicknameDomain>(
  new NicknameDomain("NO_NICKNAME_DOMAIN_SET")
);

type NicknameDomainProps = {
  passageManagerAPIBasePath: string;
  nicknameDomainName: string;
  children: React.ReactNode;
};

function getNicknameDomainPath(
  passageManagerAPIBasePath: string,
  nicknameDomainName: string
) {
  return passageManagerAPIBasePath + "/nickname-domains/" + nicknameDomainName;
}

export const NicknameDomainContextProvider: FunctionComponent<
  NicknameDomainProps
> = (props) => {
  const [nicknameDomain, setNicknameDomain] = useState(
    new NicknameDomain(
      getNicknameDomainPath(
        props.passageManagerAPIBasePath,
        props.nicknameDomainName
      )
    )
  );

  // Update nickname domain if the name given in props changes
  useEffect(() => {
    const path = getNicknameDomainPath(
      props.passageManagerAPIBasePath,
      props.nicknameDomainName
    );
    setNicknameDomain(new NicknameDomain(path));
  }, [props.nicknameDomainName]);

  return (
    <NicknameDomainContext.Provider value={nicknameDomain}>
      {props.children}
    </NicknameDomainContext.Provider>
  );
};

export function useNicknameDomain(): NicknameDomain {
  return useContext(NicknameDomainContext);
}
