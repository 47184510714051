import { ReactNode, createContext, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import { colors } from "@unlikelyai-magic/ui/variables";

const ModalDialog = styled(Dialog)`
  position: relative;
  z-index: 1001;
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: ${colors.gray["500"]};
  opacity: 0.75;
  inset: 0;
  z-index: 1000;
`;

export type ModalContext = {
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
  closeAllModals: () => void;
};

export const ModalContext = createContext<ModalContext>({
  openModal: () => null,
  closeModal: () => null,
  closeAllModals: () => null,
});

type StackedModal = {
  id: string;
  component: ReactNode;
  children?: StackedModal;
};

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [stack, setStack] = useState<StackedModal[]>([]);
  const modalTree: StackedModal | undefined = useMemo(() => {
    if (stack.length === 0) {
      return;
    }

    // Headless UI dialogs support stacking modals but only when they are nested in the DOM.
    // The nesting enables accessibility features like using ESC key to close the modal.
    // So here we transform the order stack of modals into a tree structure.
    // See: https://headlessui.com/v1/react/dialog
    return stack.reduceRight((prevModal, currentModal) => {
      return {
        ...currentModal,
        children: prevModal,
      };
    });
  }, [stack]);

  /**
   * Open a modal. If another modal is already opened, this modal will be stacked on top of it.
   */
  const openModal = (modal: ReactNode) => {
    setStack((prevValue) => [...prevValue, { id: uuidv4(), component: modal }]);
  };

  /**
   * Close the topmost modal.
   */
  const closeModal = () => {
    setStack((prevValue) => prevValue.slice(0, -1));
  };

  /**
   * Close a modal by ID.
   */
  const closeModalById = (id: string) => {
    setStack((prevValue) => prevValue.filter((modal) => modal.id !== id));
  };

  /**
   * Close all open modals.
   */
  const closeAllModals = () => {
    setStack([]);
  };

  /**
   * Recursively render modals.
   */
  const renderModal = (modal: StackedModal): ReactNode => {
    const handleClose = () => closeModalById(modal.id);
    return (
      <ModalDialog open onClose={handleClose}>
        <Overlay />
        {modal.component}
        {modal.children && renderModal(modal.children)}
      </ModalDialog>
    );
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        closeAllModals,
      }}
    >
      {children}
      {modalTree && renderModal(modalTree)}
    </ModalContext.Provider>
  );
};
