import { memo } from "react";

export const StarsIcon = memo(() => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1113_7567)">
      <path
        d="M6.66699 12.5C10.7292 12.5 12.5003 10.7908 12.5003 6.66663C12.5003 10.7908 14.259 12.5 18.3337 12.5C14.259 12.5 12.5003 14.2586 12.5003 18.3333C12.5003 14.2586 10.7292 12.5 6.66699 12.5Z"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 5.41663C4.27846 5.41663 5.41699 4.3179 5.41699 1.66663C5.41699 4.3179 6.54757 5.41663 9.16699 5.41663C6.54757 5.41663 5.41699 6.5472 5.41699 9.16663C5.41699 6.5472 4.27846 5.41663 1.66699 5.41663Z"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1113_7567">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
