import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { modalSectionStyle } from "../styles";

export type ModalFooterProps = ComponentProps<"div">;

export const ModalFooter = styled((props: ModalFooterProps) => {
  return <div {...props} />;
})`
  ${modalSectionStyle};
`;
