import { ReactNode } from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { TextBoxContent } from "./TextBoxContent";

export const TextBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.roundness.md};
  overflow: auto;
  width: 100%;
  height: fit-content;
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const DefaultHeader = styled(Spacing)`
  color: ${({ theme }) => theme.colors.text.primary.default};
  background-color: ${({ theme }) => theme.colors.background.default};
  position: sticky;
  top: 0;
  padding: 0.5rem 1rem;
  font-weight: 500;
`;

export interface TextBoxProps {
  children: ReactNode;
}

export interface TextBoxHeaderProps {
  children: ReactNode;
  className?: string;
}

const TextBoxRoot = ({ children }: TextBoxProps) => (
  <TextBoxWrapper>{children}</TextBoxWrapper>
);

const HeaderComponent = ({ children, className }: TextBoxHeaderProps) => (
  <DefaultHeader
    className={className}
    justify="space-between"
    direction="horizontal"
  >
    <Paragraph small={true}>{children}</Paragraph>
  </DefaultHeader>
);

export const TextBox = Object.assign(TextBoxRoot, {
  Header: HeaderComponent,
  Content: TextBoxContent,
});
