import { useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { InContextExampleCheckboxes } from "@dip/ui/components/in-context-example-checkboxes";
import { Slider } from "@dip/ui/components/slider";
import { PageSpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { Switch, SwitchProps, Thumb } from "@unlikelyai-magic/ui/inputs";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Sidebar, SidebarProps } from "@unlikelyai-magic/ui/sidebars";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const AnswerSwitch = styled(
  ({ checked, ...props }: { checked?: boolean } & SwitchProps) => (
    <Switch {...props} />
  )
)`
  width: 3rem;
  height: 0.5rem;
  padding: 0.7rem 0.1rem;
  ${Thumb} {
    width: 1rem;
    height: 1rem;
    transform: translateX(${({ checked }) => (checked ? 1.5 : 0.25)}rem);
  }
`;

const Container = styled(
  ({ bottomBorder, ...props }: { bottomBorder?: boolean } & SpacingProps) => (
    <Spacing {...props} />
  )
)`
  padding: ${({ theme }) => `${theme.spacings.lg} 2rem`};
  border-bottom: ${({ theme, bottomBorder }) =>
    bottomBorder && `.1rem solid ${theme.colors.background}`};
`;

const GrayText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.primary.default};
`;

type SettingsSidebarProps = Pick<SidebarProps, "open" | "onClose">;

export const SettingsSidebar = (props: SettingsSidebarProps) => {
  const {
    numOfRefinements,
    setNumOfRefinements,
    showAnsweringUL,
    setShowAnsweringUL,
  } = useSettingsSidebar();
  const { domainId } = useParams();
  const { data: domainData } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  if (!domainData) return <PageSpinner />;

  const selectedInContextExamples = domainData.inContextExamples;

  return (
    <Sidebar {...props} bodyStyle={{ padding: 0 }}>
      <Container gap="xl" bottomBorder>
        <Paragraph small>Max. refinements</Paragraph>
        <Slider
          value={numOfRefinements}
          onChange={(value) => setNumOfRefinements(value)}
          max={10}
          marks={{ 0: "0", 10: "10" }}
        />
      </Container>
      <Container bottomBorder>
        <Paragraph small>In-context examples</Paragraph>
        <InContextExampleCheckboxes
          disabled
          value={selectedInContextExamples}
        />
      </Container>
      <Container>
        <Spacing direction="horizontal" justify="space-between">
          <Spacing gap="3xs">
            <Paragraph small>Answering UL</Paragraph>
            <GrayText small>Show UL when answering questions</GrayText>
          </Spacing>
          <AnswerSwitch
            checked={showAnsweringUL}
            onChange={(checked) => {
              if (typeof checked === "boolean") setShowAnsweringUL(checked);
            }}
          />
        </Spacing>
      </Container>
    </Sidebar>
  );
};
