import { ReactNode } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  DomainEvent,
  EventAction,
  Message,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { useDomainChannel } from "../hooks";

type DomainProviderProps = {
  children?: ReactNode | ReactNode[];
};

export const DomainProvider = ({
  children = <Outlet />,
}: DomainProviderProps) => {
  const { domainId } = useParams();
  const { data, ...result } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  useDomainChannel((message: Message<DomainEvent>) => {
    if (message.name !== EventAction.UPDATED) return;
    if (message.extras?.headers.domainId !== domainId) return;
    result.refetch();
  });

  return children;
};
