import {
  NavigateOptions,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DomainMode } from "@dip/data-access/api-types";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { PageSpinner } from "@dip/ui/components/spinners";
import { ChatProvider } from "@unlikelyai-magic/ui/chat";
import { DomainHeader } from "../components";

export const DomainLayout = () => {
  const { domainId } = useParams();
  const navigate = useNavigate();
  const match = useMatch({
    path: "domains/:domainId/:mode",
  });
  const mode =
    match?.params.mode === "answering"
      ? DomainMode.ANSWERING
      : DomainMode.UNDERSTANDING;

  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  const setMode = (value: DomainMode) => {
    const navigateOptions: NavigateOptions = { replace: true };
    switch (value) {
      case DomainMode.UNDERSTANDING:
        return navigate(`/domains/${domainId}/understanding`, navigateOptions);
      case DomainMode.ANSWERING:
        return navigate(`/domains/${domainId}/answering`, navigateOptions);
    }
  };

  if (!data) return <PageSpinner />;

  return (
    <ChatProvider>
      <DomainHeader
        domainName={data.title}
        mode={mode}
        onModeChange={setMode}
      />
      <Outlet />
    </ChatProvider>
  );
};
