/**
 * Prioritises error body, else error message else error status.
 */
export function handleErrors(response: Response) {
  return response.ok
    ? Promise.resolve(response)
    : response.json().then((json) => {
        return json.message
          ? Promise.reject(json.message)
          : json.exception
          ? Promise.reject(json.exception)
          : json.body
          ? Promise.reject(json.body)
          : json.error
          ? Promise.reject(json.error)
          : response.statusText
          ? Promise.reject(response.statusText)
          : Promise.reject(response.status);
      });
}
