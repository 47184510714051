import { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Descendant, Editor, Selection, createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { EditableProps } from "slate-react/dist/components/editable";

const initialContent: Descendant[] = [
  {
    children: [{ text: "" }],
  },
];

export type TextBoxContentProps = EditableProps & {
  value?: string | string[];
  onSelectionChange?: (text?: string) => void;
};

export const TextBoxContent = styled(
  ({
    value,
    readOnly = true,
    onSelectionChange,
    ...props
  }: TextBoxContentProps) => {
    const descendantFromString = (
      valueInput?: string | string[]
    ): Descendant[] => {
      return valueInput
        ? (Array.isArray(valueInput) ? valueInput : [valueInput]).map(
            (str) => ({
              children: [{ text: str }],
            })
          )
        : initialContent;
    };

    const [stringValue, setStringValue] = useState<Descendant[]>(
      descendantFromString(value)
    );

    const editor = useMemo(() => withReact(createEditor()), []);

    useEffect(() => {
      // Setting the editor.children is required to update the content. The initialValue prop is only used on initial render of the Slate component.
      editor.children = descendantFromString(value);
    }, [editor, value]);

    const handleSelection = (selection: Selection) => {
      if (!selection) return onSelectionChange?.(undefined);
      const selectedText = Editor.string(editor, selection);
      onSelectionChange?.(selectedText);
    };

    return (
      <Slate
        editor={editor}
        initialValue={stringValue}
        onChange={setStringValue}
        onSelectionChange={handleSelection}
      >
        <Editable readOnly={readOnly} {...props} />
      </Slate>
    );
  }
)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary.default};
    background-color: ${theme.colors.background.default};
    padding: ${theme.spacings.sm} ${theme.spacings.lg};
    overflow: auto;

    ::placeholder {
      font-weight: 400;
      color: ${theme.colors.text.secondary.default};
    }

    ::selection {
      color: ${theme.colors.action.primary.default};
      background-color: ${theme.colors.background.default} !important;
    }

    &,
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  `}
`;
