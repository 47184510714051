import { contexts } from "@dip/contexts";
import { InContextExample } from "@dip/data-access/api-types";
import styled from "@emotion/styled";
import { Checkbox } from "antd";
import { CheckboxGroupProps } from "antd/lib/checkbox";

const StyledCheckboxGroup = styled(Checkbox.Group)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.action.primary.inactive
        : theme.colors.action.primary.default};
    border-color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.action.primary.inactive
        : theme.colors.action.primary.default};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.action.primary.inactive
        : theme.colors.action.primary.default};
  }
`;

interface InContextExampleCheckboxesProps {
  value: CheckboxGroupProps["value"];
  disabled?: boolean | InContextExample[];
  onChange?: CheckboxGroupProps["onChange"];
}

export const InContextExampleCheckboxes = ({
  disabled,
  value,
  onChange,
}: InContextExampleCheckboxesProps) => {
  const checkboxOptions = Object.values(InContextExample)
    .filter((example) => example !== InContextExample.NONE)
    .map((example) => ({
      label: contexts[example].title,
      value: example,
      disabled:
        disabled === true ||
        (disabled !== false && disabled?.includes(example)),
    }));

  return (
    <StyledCheckboxGroup
      options={checkboxOptions}
      value={value}
      disabled={typeof disabled === "boolean" && disabled}
      onChange={onChange}
    />
  );
};
