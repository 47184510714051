import { ChannelResult, useChannel as useAblyChannel } from "ably/react";
import { Message } from "./message";

export type UseChannelCallback = (message: Message) => void;

export const useChannel = (
  channel: string,
  callback: UseChannelCallback
): ChannelResult => {
  return useAblyChannel(channel, (message: Message) => {
    const data =
      typeof message.data === "string"
        ? JSON.parse(message.data)
        : message.data;
    callback({
      ...message,
      data,
    });
  });
};
