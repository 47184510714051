import React, { PropsWithChildren, useState } from "react";
import { Upload, UploadFile, UploadProps, message } from "antd";

interface FileUploadProps extends UploadProps {
  handleSuccess: (files: UploadFile[]) => void;
  multiple?: boolean;
  maxFileSizeBytes?: number;
}

const MEGABYTE = 1024 * 1024;

export const FileUploader = ({
  multiple = false,
  handleSuccess,
  maxFileSizeBytes = 5 * MEGABYTE,
  children,
  ...props
}: PropsWithChildren<FileUploadProps>) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleBeforeUpload = (file: File): boolean => {
    if (file.size > maxFileSizeBytes) {
      message.error(
        `${file.name} exceeds the maximum size of ${
          maxFileSizeBytes / 1024 / 1024
        }MB.`
      );
      return false;
    }
    return true;
  };

  const handleUpload = () => {
    handleSuccess(fileList);
  };

  const uploadProps: UploadProps = {
    fileList,
    multiple,
    listType: "picture",
    beforeUpload: handleBeforeUpload,
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
    onRemove: (file) => {
      setFileList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
    },
    customRequest: ({ onSuccess }) => {
      onSuccess && onSuccess("ok");
      handleUpload();
    },
    ...props,
  };

  return (
    <Upload {...uploadProps} data-testid="upload-input">
      {children}
    </Upload>
  );
};
