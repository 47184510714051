import { theme } from "@dip/theme";
import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from "antd";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

export type PopoverStatus = "error" | "warning" | "default";

export type PopoverProps = Omit<AntdPopoverProps, "title" | "content"> & {
  status?: PopoverStatus;
  title?: string;
  content?: string;
};

const getStatusStyles = (status: PopoverStatus) => {
  switch (status) {
    case "error":
      return {
        color: theme.colors.semantic.error.default,
        backgroundColor: theme.colors.semantic.error.background,
      };
    case "warning":
      return {
        color: theme.colors.semantic.warning.default,
        backgroundColor: theme.colors.semantic.warning.background,
      };
    case "default":
    default:
      return {
        color: theme.colors.text.primary.default,
        backgroundColor: theme.colors.background.default,
      };
  }
};

export const Popover = ({
  children,
  status = "default",
  title,
  content,
  overlayInnerStyle = {},
  ...props
}: PopoverProps) => {
  const { color, backgroundColor } = getStatusStyles(status);

  return (
    <AntdPopover
      color={backgroundColor}
      overlayInnerStyle={{
        borderRadius: theme.roundness.md,
        boxShadow: theme.shadows.md,
        ...overlayInnerStyle,
      }}
      title={title && <Paragraph color={color}>{title}</Paragraph>}
      content={
        content && (
          <Paragraph small color={color}>
            {content}
          </Paragraph>
        )
      }
      {...props}
    >
      {children}
    </AntdPopover>
  );
};
