import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { colors } from "@unlikelyai-magic/ui/variables";
import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";

export type ModalProps = Omit<ComponentProps<"div">, "ref">;

const ModalRoot = styled(({ children, ...props }: ModalProps) => {
  return <Dialog.Panel {...props}>{children}</Dialog.Panel>;
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: ${colors.white};
  border-radius: 2rem;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    border-top: 1px solid ${colors.gray["100"]};
  }
`;

export const Modal = Object.assign(ModalRoot, {
  Header: ModalHeader,
  Content: ModalContent,
  Footer: ModalFooter,
});
