import { ComponentProps, useState } from "react";
import { Link } from "react-router-dom";
import { DomainMode } from "@dip/data-access/api-types";
import { theme } from "@dip/theme";
import { PrimaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { Cog8ToothIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { TransparentButton } from "@unlikelyai-magic/ui/buttons";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";
import { Switch, Thumb } from "@unlikelyai-magic/ui/inputs";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { SettingsSidebar } from "./SettingsSidebar";

const Header = styled.header`
  display: grid;
  position: sticky;
  top: 0;
  z-index: 100;
  grid-template-columns: 1fr 1fr 1fr;
  height: 3rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.component.background[1]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.component.border[1]};
  padding: 0 ${({ theme }) => theme.spacings.lg};
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const HeaderButton = styled(TransparentButton)`
  height: 3rem;
  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ModeName = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
`;

const DomainName = styled(Paragraph)`
  white-space: nowrap;
  font-weight: medium;
  color: ${({ theme }) => theme.colors.text.primary.default};
`;

const ModeToggle = styled(Switch)<{ checked: boolean }>`
  height: 2.25rem;
  width: 3.5rem;
  ${Thumb} {
    width: 1.75rem;
    height: 1.75rem;
    transform: translateX(${({ checked }) => (checked ? 1.3375 : 0.25)}rem);
    ${({ theme, checked }) =>
      checked && {
        background: theme.colors.action.primary[2].default,
        color: theme.colors.text.dark.default,
      }};
  }
`;

const PublishButton = styled(PrimaryButton)`
  height: 2.25rem;
`;

type DomainHeaderProps = ComponentProps<"header"> & {
  domainName: string;
  mode: DomainMode;
  onModeChange: (mode: DomainMode) => void;
};

export const DomainHeader = ({
  domainName,
  mode,
  onModeChange,
  ...props
}: DomainHeaderProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleMode = () => {
    onModeChange(
      mode === DomainMode.UNDERSTANDING
        ? DomainMode.ANSWERING
        : DomainMode.UNDERSTANDING
    );
  };

  const handleSettingsClick = () => setIsSettingsOpen(true);
  const handleSettingsClose = () => setIsSettingsOpen(false);

  return (
    <Header {...props}>
      <LogoLink to="/">
        <Icon
          icon={UnlikelyAiLogo}
          fill={theme.colors.text.secondary.default}
          size="1.5rem"
        />
      </LogoLink>
      <Spacing direction="horizontal" justify="center" gap="xs">
        <ModeName small>
          {mode === DomainMode.UNDERSTANDING ? "Understanding" : "Answering"}
        </ModeName>
        <DomainName small>{domainName}</DomainName>
      </Spacing>
      <Spacing
        direction="horizontal"
        gap="sm"
        justify="flex-end"
        items="center"
      >
        <ModeToggle
          checked={mode === DomainMode.UNDERSTANDING}
          onChange={toggleMode}
          icon={DocumentIcon}
        />
        <PublishButton mode={mode} disabled>
          Publish
        </PublishButton>
        <HeaderButton icon={<Cog8ToothIcon />} onClick={handleSettingsClick} />
      </Spacing>
      <SettingsSidebar open={isSettingsOpen} onClose={handleSettingsClose} />
    </Header>
  );
};
