import styled from "@emotion/styled";
import { colors, fontSizes, spacings } from "@unlikelyai-magic/ui/variables";
import { Steps, StepsProps } from "./Steps";

type NavigationStepsProps = Pick<StepsProps, "items" | "current" | "className">;

export const NavigationSteps = styled((props: NavigationStepsProps) => {
  return <Steps type="navigation" size="small" {...props} />;
})`
  width: fit-content;
  padding-top: 0;
  gap: 2.75rem; // 1rem + 0.75rem + 1rem (spacing + caret icon + spacing)

  &.ant-steps-small {
    .ant-steps-item-container {
      margin-left: 0;
      padding-bottom: 0;
    }

    .ant-steps-item:before {
      display: none;
    }

    .ant-steps-item-icon {
      color: ${colors.gray["500"]};
      background: ${colors.gray["50"]};
      border-radius: ${spacings.sm};
      border-color: currentColor;
      font-size: ${fontSizes.md};
      display: inline-flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      & > .ant-steps-icon {
        color: currentColor;
      }
    }

    .ant-steps-item-active .ant-steps-item-icon {
      color: ${colors.blue["600"]};
      background: ${colors.blue["100"]};
    }

    .ant-steps-item-finish .ant-steps-item-icon {
      background: ${colors.blue["500"]};
      color: ${colors.white};
    }

    .ant-steps-item {
      padding-left: 0;

      &:after {
        margin-left: ${spacings.lg};
        border-color: ${colors.black};
        border-width: 1.5px;
        width: 0.75rem;
        height: 0.75rem;
        margin-top: -${spacings.xs};
      }
    }
  }
`;
