/* eslint-disable */
// @generated by protobuf-ts 2.9.3 with parameter generate_dependencies,ts_nocheck,eslint_disable,add_pb_suffix
// @generated from protobuf file "ai/unlikely/core/proto/tag.proto" (package "ai_unlikely_core_proto", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "./user_pb";
import { Timestamp } from "../../../../google/protobuf/timestamp_pb";
import { Uuid } from "./uuid_pb";
/**
 * Defines the tags interface
 *
 * @generated from protobuf message ai_unlikely_core_proto.Tag
 */
export interface Tag {
    /**
     * @generated from protobuf field: ai_unlikely_core_proto.Uuid id = 1;
     */
    id?: Uuid;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 4;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: ai_unlikely_core_proto.User author = 5;
     */
    author?: User;
    /**
     * @generated from protobuf field: ai_unlikely_core_proto.TagType type = 6;
     */
    type: TagType;
}
/**
 * @generated from protobuf enum ai_unlikely_core_proto.TagType
 */
export enum TagType {
    /**
     * protolint:disable:this ENUM_FIELD_NAMES_PREFIX ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH
     *
     * @generated from protobuf enum value: UNKNOWN_TAG_TYPE = 0;
     */
    UNKNOWN_TAG_TYPE = 0,
    /**
     * protolint:disable:this ENUM_FIELD_NAMES_PREFIX
     *
     * @generated from protobuf enum value: PROJECT_TAG_TYPE = 1;
     */
    PROJECT_TAG_TYPE = 1,
    /**
     * protolint:disable:this ENUM_FIELD_NAMES_PREFIX
     *
     * @generated from protobuf enum value: TICKET_TAG_TYPE = 2;
     */
    TICKET_TAG_TYPE = 2,
    /**
     * protolint:disable:this ENUM_FIELD_NAMES_PREFIX
     *
     * @generated from protobuf enum value: SEMANTIC_SCENARIO_TAG_TYPE = 3;
     */
    SEMANTIC_SCENARIO_TAG_TYPE = 3,
    /**
     * protolint:disable:this ENUM_FIELD_NAMES_PREFIX
     *
     * @generated from protobuf enum value: TRANSLATION_PAIR_TAG_TYPE = 4;
     */
    TRANSLATION_PAIR_TAG_TYPE = 4,
    /**
     * protolint:disable:this ENUM_FIELD_NAMES_PREFIX
     *
     * @generated from protobuf enum value: TEMPLATE_TAG_TYPE = 5;
     */
    TEMPLATE_TAG_TYPE = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class Tag$Type extends MessageType<Tag> {
    constructor() {
        super("ai_unlikely_core_proto.Tag", [
            { no: 1, name: "id", kind: "message", T: () => Uuid, options: { "buf.validate.field": { required: true } } },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "author", kind: "message", T: () => User },
            { no: 6, name: "type", kind: "enum", T: () => ["ai_unlikely_core_proto.TagType", TagType] }
        ]);
    }
    create(value?: PartialMessage<Tag>): Tag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.value = "";
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<Tag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tag): Tag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ai_unlikely_core_proto.Uuid id */ 1:
                    message.id = Uuid.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp modified_at */ 4:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* ai_unlikely_core_proto.User author */ 5:
                    message.author = User.internalBinaryRead(reader, reader.uint32(), options, message.author);
                    break;
                case /* ai_unlikely_core_proto.TagType type */ 6:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ai_unlikely_core_proto.Uuid id = 1; */
        if (message.id)
            Uuid.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp modified_at = 4; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ai_unlikely_core_proto.User author = 5; */
        if (message.author)
            User.internalBinaryWrite(message.author, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ai_unlikely_core_proto.TagType type = 6; */
        if (message.type !== 0)
            writer.tag(6, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ai_unlikely_core_proto.Tag
 */
export const Tag = new Tag$Type();
