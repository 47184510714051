import { Global, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
// TODO: ideally we should look into using a headless component rather than antd
import { Slider as AntdSlider } from "antd";
import { SliderSingleProps } from "antd/lib/slider";

export type SliderProps = Pick<
  SliderSingleProps,
  | "disabled"
  | "autoFocus"
  | "min"
  | "max"
  | "step"
  | "marks"
  | "onChange"
  | "onAfterChange"
  | "tooltip"
  | "value"
>;

export const Slider = styled((props: SliderProps) => {
  const theme = useTheme();
  return (
    <>
      <Global
        styles={css`
          .ant-slider-tooltip {
            padding: 0;
            .ant-tooltip-arrow {
              visibility: hidden;
            }
            .ant-tooltip-inner {
              padding: 0;
              min-height: 0.5rem;
              background-color: transparent;
              box-shadow: none;
              color: ${theme.colors.action.primary.default};
              line-height: 150%;
              font-size: 0.875rem;
            }
          }
        `}
      />
      <AntdSlider {...props} />
    </>
  );
})`
  ${({ theme }) => css`
    &.ant-slider {
      vertical-align: middle;
      .ant-slider-track {
        background-color: ${theme.colors.action.primary.default};
        height: 0.5rem;
        border-radius: 7.5rem 0 0 7.5rem;
      }
      .ant-slider-rail {
        height: 0.5rem;
      }
      .ant-slider-handle {
        background-color: ${theme.colors.action.primary.default};
        border: none;
        stroke-width: 1px;
        margin-top: -0.19rem;
        stroke: #fff;
        &:focus {
          box-shadow: 0 0 0 5px rgba(36, 7, 43, 0.25);
        }
        &:hover {
          box-shadow: 0 0 0 5px rgba(36, 7, 43, 0.25);
        }
      }
      .ant-slider-step > .ant-slider-dot {
        border: none;
        width: 0;
      }
    }
  `}
`;
