import {
  GetSourcedExplanationObject,
  SourcedExplanationWithIds,
} from "./types";

export function getSourcedExplanation({
  summarisedExplanation,
  solutionIdToSourceId,
}: GetSourcedExplanationObject): SourcedExplanationWithIds[] {
  return summarisedExplanation.map((explanationAndId) => {
    // Concatenate the explanation substrings and their associated simple ids. For example, turn
    // {"explanation": "First substring ", "solutionIds": [uuid1, uuid2]} and {"explanation": " and
    // second substring", "solutionIds": [uuid3]}
    // into "First substring[1,2] and second substring[3]"
    const simpleIds = explanationAndId.solutionIds
      .filter((solutionId) => solutionIdToSourceId.has(solutionId))
      .map((solutionId) => solutionIdToSourceId.get(solutionId));
    const idSuffix = simpleIds.length ? `[${simpleIds.join(",")}]` : "";

    // crazy regex replacement courtesy of Bing AI!
    // replaces all multi-references with multiple individual references,
    // e.g. ^[1,2,3] with ^[1]^[2]^[3]
    // if source value does not begin with https, add it
    const sourcedExplanationString =
      `${explanationAndId.explanation}${idSuffix}`.replace(
        /\^\[(\d+(,\s*\d+)*)]/g,
        (_, p1) => {
          return (
            "^[" + p1.split(",").join("]^[", p1.split(",").length - 1) + "]"
          );
        }
      );

    // split on the [ and ] characters, leaving them in the resultant array, removing the commas between sources
    // e.g. "A spouse is a human[1,2]" becomes ["A spouse is a human", "[", "1", "2", "]"]
    const regex = /(?=\[)|(?<=\[)|(?=\])|(?<=\])|(?<=\[.*?),(?=.*?\])/g;
    const explanationStringSections = sourcedExplanationString.split(regex);

    return {
      explanationAndIds: explanationAndId,
      sourcedExplanationStrings: explanationStringSections,
    };
  });
}
