import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ENV, config } from "@dip/config";
import { store } from "@dip/data-access/dip-api-service";
import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import { createMockServer } from "@unlikelyai-magic/shared/mocking";
import App from "./app/App";
import "./app/App.css";
import { setupDatadog } from "./logging/datadog";
import { mockDipApi } from "./msw/mswHandlers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const render = async () => {
  const ablyClient = new Ably.Realtime({
    authUrl: `${config.DIP_API_BASE_URL}/realtime/token`,
    authMethod: "POST",
  });

  // do not send any logs to DataDog while in development mode
  if (config.ENV !== ENV.DEVELOPMENT) setupDatadog();

  // setup MSW endpoints
  if (config.ENV === ENV.DEVELOPMENT) {
    const mockServer = createMockServer(mockDipApi);
    await mockServer.listen();
  }

  root.render(
    <StrictMode>
      <BrowserRouter>
        <AblyProvider client={ablyClient}>
          <Provider store={store}>
            <App />
          </Provider>
        </AblyProvider>
      </BrowserRouter>
    </StrictMode>
  );
};

render();
