import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { FileUploader } from "@dip/ui/components/file-uploader";
import styled from "@emotion/styled";
import {
  ArrowUpOnSquareIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { UploadFile } from "antd";
import Papa from "papaparse";
import { v4 as uuid } from "uuid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";
import { sleep } from "@unlikelyai-magic/utils";
import { ExampleRow, ExamplesTable } from "../components";
import { StepLayout } from "../layouts";

const SuccessIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.colors.semantic.success.default};
`;

const ErrorIcon = styled(ExclamationCircleIcon)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
`;

const BaseText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.primary.default};
  font-size: 0.9rem;
`;

const ErrorText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
  width: 70%;
  text-align: right;
`;

const SuccessText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.semantic.success.default};
`;

type CSVRow = { Problem: string; Answer: string; Reasoning: string };

interface ExamplesStepProps {
  onGoBack: () => void;
}

enum UploadStatus {
  Loading = "LOADING",
  Success = "SUCCESS",
  Failed = "FAILED",
  Initial = "INITIAL",
}

export const ExamplesStep = ({ onGoBack }: ExamplesStepProps) => {
  const [{ status, message }, setUploadCSVStatus] = useState({
    status: UploadStatus.Initial,
    message: "",
  });
  const [examples, setExamples] = useState<ExampleRow[]>([]);

  const handleCSVUpload = (files: UploadFile[]) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target?.result as string;

      Papa.parse<CSVRow>(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: ({ data }) => {
          const exampleRowData = data.filter(
            (row) => row.Problem && row.Answer && row.Reasoning
          );

          if (!exampleRowData.length) {
            setUploadCSVStatus({
              status: UploadStatus.Failed,
              message:
                "CSV must use 'Problem', 'Answer', and 'Reasoning' columns",
            });
            return;
          }

          setExamples((prevData) => [
            ...prevData,
            ...exampleRowData.map(({ Problem, Answer, Reasoning }) => ({
              key: uuid(),
              problem: Problem,
              answer: Answer,
              reasoning: Reasoning,
            })),
          ]);

          setUploadCSVStatus({
            status: UploadStatus.Success,
            message: exampleRowData.length.toString(),
          });
        },
        error: (error: Error) =>
          setUploadCSVStatus({
            status: UploadStatus.Failed,
            message: error.message,
          }),
      });
    };

    reader.readAsText(file.originFileObj as Blob);
  };

  const isLoading = status === UploadStatus.Loading;

  return (
    <StepLayout
      title={
        <Spacing direction="horizontal" justify="space-between">
          <Heading level="h4">Add examples</Heading>
          <FileUploader
            fileList={[]}
            showUploadList={false}
            handleSuccess={async (files) => {
              setUploadCSVStatus({
                status: UploadStatus.Loading,
                message: "",
              });
              // add an artificial half-second delay so that the loading state does not flicker
              await sleep(500);
              handleCSVUpload(files);
            }}
            accept=".csv"
          >
            <SecondaryButton
              icon={!isLoading && <ArrowUpOnSquareIcon />}
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : "Add via CSV"}
            </SecondaryButton>
          </FileUploader>
        </Spacing>
      }
      headerDescription={
        <Spacing direction="horizontal" justify="space-between" gap="5xl">
          <Spacing gap="3xs">
            <BaseText>You can add examples manually or via a CSV.</BaseText>
            <Link
              to="/examplesTemplate.csv"
              target="_blank"
              download="template.csv"
            >
              Download the template
            </Link>
          </Spacing>
          {isLoading && <BaseText>Please wait a few moments</BaseText>}
          {status === UploadStatus.Success && (
            <Spacing direction="horizontal" items="center" gap="xs">
              <SuccessIcon width={20} />
              <SuccessText>{message + " examples added"}</SuccessText>
            </Spacing>
          )}
          {status === UploadStatus.Failed && (
            <Spacing
              direction="horizontal"
              justify="right"
              items="center"
              gap="xs"
            >
              <ErrorIcon width={20} />
              <ErrorText>{message}</ErrorText>
            </Spacing>
          )}
        </Spacing>
      }
      onGoBack={onGoBack}
      content={
        <ExamplesTable
          rows={examples}
          setRows={setExamples}
          onGoBack={onGoBack}
          isDisabled={isLoading}
        />
      }
    />
  );
};
