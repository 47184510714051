import styled from "@emotion/styled";
import { Tabs as AntDTabs, TabsProps as AntDTabsProps } from "antd";

/* TODO: Move this component to the generic ui library and away from AntD
https://linear.app/unlikelyai/issue/UA-385/move-dip-tabs-to-a-common-ui-component */

const StyledTabs = styled(AntDTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.text.primary.default};
  }
  .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.colors.text.primary.default};
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.text.secondary.default};
    &:hover {
      color: ${({ theme }) => theme.colors.text.primary.default};
    }
  }
`;

export type TabsProps = AntDTabsProps;

export const Tabs = (props: TabsProps) => <StyledTabs {...props} />;
