import { Passage } from "../../ul/ul-element";
import { DebugMessageSolverLog } from "../reasoning-engine";
import {
  StatusAborted,
  StatusCompleted,
  StatusRunning,
  StatusScheduled,
} from "./status";

export type SolverNodeState = {
  solverNodeId: string;
  scheduledAt: number;
  status: SolverNodeStatus;
  // The id of the actual solver class this invocation was on; this is an index into
  // `questionState.solverInfo`.
  solverId: number;
  // All log messages emitted by this solver node
  logMessages: DebugMessageSolverLog[];
  // Ids for all solutions emitted by this solver node
  solutionIds: string[];
  // Ids for all queries recursively asked by this solver: keys into `questionState.queryStates`.
  childQueryIds: string[];
  executingSolverId: string | null;
  // Ids for all queries that invoked this solver: keys into `questionState.queryStates`.
  // Right now, there should only be one because there's no caching happening on the BE.
  parentQueryIds: string[];
  // Denormalised information: the parent query this solver is solving
  parentUlQuery: Passage | null;
  // If this node is serving solutions from the cache
  cached: boolean | null;
};

export type SolverNodeStatus =
  | StatusScheduled
  | StatusRunning
  | StatusCompleted
  | StatusAborted;

export const makeEmptySolverNodeState = (
  solverNodeId: string,
  solverId: number,
  scheduledAt: number,
  parentQueryId: string
): SolverNodeState => {
  return {
    solverNodeId,
    scheduledAt,
    status: { "@type": "SCHEDULED" },
    solverId,
    logMessages: [],
    solutionIds: [],
    childQueryIds: [],
    executingSolverId: null,
    parentQueryIds: [parentQueryId],
    parentUlQuery: null,
    cached: null,
  };
};
