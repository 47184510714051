import { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Form, Input, Typography } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@unlikelyai-magic/ui/typography";
import { StepLayout } from "../layouts";

const ErrorContainer = styled(Spacing)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
`;

const ErrorText = styled(Typography.Text)`
  color: inherit;
`;

const ExclamationCircleIconContainer = styled(ExclamationCircleIcon)`
  color: inherit;
  height: 1.5rem;
`;

const TextArea = styled(Input.TextArea)`
  border-radius: ${({ theme }) => theme.roundness.sm};
  resize: none;
`;

interface DomainStepProps {
  onGoBack: () => void;
  onGoForward: () => void;
}

export const DomainInputStep = ({ onGoBack, onGoForward }: DomainStepProps) => {
  const form = Form.useFormInstance();
  const [hasError, setHasError] = useState(false);
  const domainDescriptionFieldName = "domainDescription";

  // The ref is used to focus the input when the component mounts
  const inputRef = useRef<TextAreaRef>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const isDomainInputError = async () => {
    try {
      await form.validateFields([domainDescriptionFieldName]);
      setHasError(false);
      return false;
    } catch (errorInfo) {
      setHasError(true);
      return true;
    }
  };

  const handleDomainInputChange = async () => await isDomainInputError();

  const handleContinue = async () =>
    !(await isDomainInputError()) && onGoForward();

  return (
    <StepLayout
      title={<Heading level="h4">Define domain</Heading>}
      headerDescription={
        <Spacing>
          <Text type="secondary">
            Provide the domain description you would like understood.
          </Text>
          {hasError && (
            <ErrorContainer gap="xs" direction="horizontal" items="center">
              <ExclamationCircleIconContainer fontSize={4} />
              <ErrorText>Please produce a domain description.</ErrorText>
            </ErrorContainer>
          )}
          <Form.Item
            name={domainDescriptionFieldName}
            rules={[{ required: true }]}
            validateTrigger="onChange"
            help=""
          >
            <TextArea
              ref={inputRef}
              rows={15}
              onChange={handleDomainInputChange}
            />
          </Form.Item>
          <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
        </Spacing>
      }
      onGoBack={onGoBack}
    />
  );
};
