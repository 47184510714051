import {
  Path,
  RequestHandler,
  ResponseResolver,
  RestContext,
  RestRequest,
  rest,
} from "msw";
import { MockDatabase } from "./MockDatabase";

export class MockApi {
  private readonly _baseUrl: string;
  private readonly _database: Loki;
  private readonly _handlers: Array<RequestHandler> = [];

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
    this._database = MockDatabase.getInstance();
  }

  public get handlers(): Array<RequestHandler> {
    return this._handlers;
  }

  public get database(): Loki {
    return this._database;
  }

  private formatUrl(url: Path): Path {
    return `${this._baseUrl}${url}`;
  }

  get(url: Path, handler: ResponseResolver<RestRequest<never>, RestContext>) {
    this._handlers.push(rest.get(this.formatUrl(url), handler));
  }

  post(url: Path, handler: ResponseResolver<RestRequest, RestContext>) {
    this._handlers.push(rest.post(this.formatUrl(url), handler));
  }

  put(url: Path, handler: ResponseResolver<RestRequest, RestContext>) {
    this._handlers.push(rest.put(this.formatUrl(url), handler));
  }

  delete(url: Path, handler: ResponseResolver<RestRequest, RestContext>) {
    this._handlers.push(rest.delete(this.formatUrl(url), handler));
  }

  patch(url: Path, handler: ResponseResolver<RestRequest, RestContext>) {
    this._handlers.push(rest.patch(this.formatUrl(url), handler));
  }
}
