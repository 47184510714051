/**
 * All the reasoning data associated with answering a question
 */
import { Passage, UlElement, Uuid } from "../ul/ul-element";

export type ReasoningData = {
  steps: ReasoningStep[];
  question: string;
  options: QuestionOptions;
};

/**
 * A point-in-time view of the question processing after a given processing step
 */
export type ReasoningStep = {
  budgetedQueryList: BudgetedQueryListEntry[];
  reasoningGraph: GraphNode;
};

export type GraphNode = NonQueryNode | QueryNode;

export type BaseNode = {
  id: string;
  budget: number;
  state: string;
  query: Passage;
  generation: number;
  children: GraphNode[];
};

export type NonQueryNode = BaseNode & {
  stepType: Exclude<StepType, StepType.QUERY>;
};

export type QueryNode = BaseNode & {
  stepType: StepType.QUERY;
  constraints: Map<
    Uuid,
    { numConstraints: number; constraintsSample: UlElement[] }
  >;
  numSolutions: number;
  solutionsSample: Map<Uuid, UlElement>[];
};

export enum StepType {
  QUERY = "QUERY",
  REASONING = "REASONING",
  PARTIAL_REASONING = "PARTIAL_REASONING",
  CONNECTOR = "CONNECTOR",
  YES_NO = "YES_NO",
  DONT_KNOW = "DONT_KNOW",
  SEMANTIC_EQUIVALENCE = "SEMANTIC_EQUIVALENCE",
  HYPOTHETICAL = "HYPOTHETICAL",
  OR = "OR",
  AND = "AND",
  UNSPECIFIED_NODE_CLASS = "UNSPECIFIED_NODE_CLASS",
}

type BudgetedQueryListEntry = {
  query: string;
  budget: number;
};

type QuestionOptions = {
  reasoningEffort: string;
  useComputationUnits: boolean;
  maxResults: number;
};

/**
 * Function that can be used to filter a reasoningGraph to just the steps that produced a solution.
 * */
export function isPartOfSolutionPath(reasoningGraph: GraphNode): boolean {
  // If this is a node that could have solutions, check if it has any solutions
  if ("numSolutions" in reasoningGraph) {
    return reasoningGraph.numSolutions > 0;
  }
  // If this is a reasoning path node, check if any of its top level children have solutions
  return (
    reasoningGraph.children.find(
      (child) => "numSolutions" in child && child.numSolutions > 0
    ) !== undefined
  );
}
