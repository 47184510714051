import { ReactElement, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { Message, MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors } from "@unlikelyai-magic/ui/variables";
import { ChatMessage } from "./ChatMessage";

const Container = styled(Spacing)`
  ${({ theme }) => css`
    padding: ${theme.spacings.lg} ${theme.spacings.sm};
    height: 100%;
  `};
`;

const MessagesContainer = styled(Spacing)`
  height: 100%;
  flex: 1;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.component.background[2]};
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin: ${({ theme }) => theme.spacings.lg} 0;
  }
`;

const EmptyStateContainer = styled(Spacing)`
  flex: 1;
`;

type ChatContainerProps = SpacingProps & {
  domainName: string;
};

export const ChatContainer = ({ domainName, ...props }: ChatContainerProps) => {
  const { messages } = useChat();

  /* TODO: Only messages added to the chat should animate. shouldAnimate accomplishes that in a hacky way by only animating messages
  after the component has mounted. There is likely a much better way of doing this that we should explore should we keep the chat
  interface in the future. */
  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => setShouldAnimate(true), []);

  const animateMessage = (message: Message): ReactElement => (
    <motion.div
      key={message.id}
      initial={
        shouldAnimate
          ? {
              opacity: 0,
              translateY: message.author === MessageAuthor.Bot ? -24 : 24,
            }
          : false
      }
      animate={{ opacity: 1, translateY: 0 }}
    >
      <ChatMessage message={message} />
    </motion.div>
  );

  return (
    <Container>
      <MessagesContainer {...props} gap="xl">
        {messages.length > 0 ? (
          <AnimatePresence mode="popLayout">
            {messages.map(animateMessage)}
          </AnimatePresence>
        ) : (
          <EmptyStateContainer justify="center" items="center">
            <Icon icon={UnlikelyAiLogo} size="3.25rem" />
            <Paragraph>What can I help you with?</Paragraph>
            <Paragraph color={colors.neutral["400"]}>
              Ask me questions about {domainName}
            </Paragraph>
          </EmptyStateContainer>
        )}
      </MessagesContainer>
    </Container>
  );
};
