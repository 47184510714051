type SourceLinksProps = {
  sources: Map<string, string[]>;
};

export const SourceLinks = ({ sources }: SourceLinksProps) => {
  return (
    <>
      {Array.from(sources.entries()).map(([ref, urls], id) => {
        if (!urls.length) {
          return null;
        }
        // Get the first URL only
        // TODO: this could be extended to leverage the chain of sources to explain where we got
        //  the deeper source from - (e.g. source X from source Y) - the information is present in
        //  the sources map and ready to be used
        const url = urls[0];
        return (
          <span key={`${url}_${id}`}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="text-base-slate-600"
            >
              [{ref}] {url}
            </a>
          </span>
        );
      })}
    </>
  );
};
