import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import { FeedbackRequestedFrom } from "@dip/data-access/api-types";
import {
  RealtimeChannel,
  useCreateFeedbackMutation,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { PromptInput } from "@dip/ui/components/inputs";
import { PageSpinner } from "@dip/ui/components/spinners";
import { Tabs, TabsProps } from "@dip/ui/components/tabs";
import { CodeTextBox, NLTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { ChannelProvider } from "ably/react";
import moment from "moment";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { UnderstandingLoadingCard } from "./UnderstandingLoadingCard";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacings.lg};
  width: 100%;

  @media (min-width: ${screenBreakpoints.lg}) {
    grid-template-columns: 0.5fr 1fr;
  }
`;

// TODO: Re-enable once https://linear.app/unlikelyai/issue/UA-409/allow-users-to-provide-feedback-on-specific-pieces-of-ul is fixed.
// const Code = styled.code`
//   white-space: pre-line;
// `;

const Representation = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.05rem solid ${({ theme }) => theme.colors.component.border[1]};
  /* TODO: We probably shouldn't use vh here, but this appears to be the only height setting that fixes the height when changing tabs.
  https://linear.app/unlikelyai/issue/UA-402/ul-tab-creates-a-scroll-on-the-whole-page-when-content-overflows */
  height: 92vh;
`;

const RepresentationTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    padding: 0 1rem;
  }

  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }

  height: 100%;
  overflow: auto;
`;

const UpdatedAt = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.xl};
`;

export const UnderstandingDomainPage = () => {
  const { domainId } = useParams();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );
  const [selectedRepresentation, setSelectedRepresentation] =
    useState<string>();
  const [createFeedback] = useCreateFeedbackMutation();
  const { numOfRefinements } = useSettingsSidebar();

  const versions = data?.understandings[0].versions;
  const latestVersion = versions?.[versions.length - 1];
  const showFeedbackInput =
    // If feedback is requested from a human, show the input
    latestVersion?.feedbackRequestedFrom === FeedbackRequestedFrom.HUMAN ||
    // If we are done understanding, we should still show the input because
    // the user may still have feedback to apply.
    latestVersion?.feedbackRequestedFrom === null;

  // We assume there is only one understanding per domain. Eventually we will support multiple
  // understandings and this will need to be updated.
  const understandingId = data?.understandings[0].id ?? "";

  const handleFeedback = async (value: string) => {
    if (!data) return;

    const feedback = selectedRepresentation
      ? `In the representation snippet '''${selectedRepresentation}''' apply the feedback '${value}'`
      : value;

    await createFeedback({
      feedback,
      domainId: data.id,
      domainUnderstandingId: understandingId,
      refinements: numOfRefinements,
    });

    // Clear the selected representation
    setSelectedRepresentation(undefined);
  };

  /**
   * Handles the selection of a representation snippet. Does not clear the selection, this is
   * done explicitly with the clear button.
   */
  const handleSelection = (selection?: string) =>
    selection && setSelectedRepresentation(selection);

  if (!data) return <PageSpinner />;

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Natural language",
      children: (
        <CodeTextBox
          value={latestVersion?.representationCommentary.map(
            (rule, i) => `Rule ${i + 1}: ${rule}`
          )}
        />
      ),
    },
    {
      key: "2",
      label: "Universal language",
      children: (
        <CodeTextBox
          value={latestVersion?.representation.map(
            (rule, i) => `Rule ${i + 1}: ${rule}`
          )}
          onSelectionChange={handleSelection}
        />
      ),
    },
  ];

  return (
    <Layout>
      <NLTextBox header="Domain" content={data.content} />
      {latestVersion ? (
        <Representation>
          <RepresentationTabs
            items={tabItems}
            onChange={() => setSelectedRepresentation(undefined)}
            tabBarStyle={{ margin: 0 }}
          />
          {showFeedbackInput && (
            <PromptInput
              placeholder="Provide feedback..."
              onSubmit={handleFeedback}
              // TODO: Re-enable once https://linear.app/unlikelyai/issue/UA-409/allow-users-to-provide-feedback-on-specific-pieces-of-ul is fixed.
              // header={
              //   selectedRepresentation && (
              //     <Spacing
              //       direction="horizontal"
              //       justify="space-between"
              //       items="center"
              //     >
              //       <Spacing direction="horizontal" gap="sm" items="center">
              //         <Icon icon={ArrowUturnRightIcon} size="1em" />
              //         <Code>{selectedRepresentation}</Code>
              //       </Spacing>
              //       <TransparentButton
              //         size="sm"
              //         icon={<XMarkIcon />}
              //         onClick={() => setSelectedRepresentation(undefined)}
              //       />
              //     </Spacing>
              //   )
              // }
            />
          )}
          <UpdatedAt small>{`Last updated ${moment(
            new Date(latestVersion.dateCreated)
          ).fromNow()}`}</UpdatedAt>
        </Representation>
      ) : (
        <ChannelProvider
          channelName={`${RealtimeChannel.PIPELINE}:${understandingId}`}
          /* Get the previous Ably message so we know which state the domain understanding is in. This ensures
      the backend does not have to write the status of the domain understanding to the database each time the status
      changes. The change in status is already encoded in Ably, and so we can simply pull it from there. */
          options={{ params: { rewind: "1" } }}
        >
          <UnderstandingLoadingCard domainUnderstandingId={understandingId} />
        </ChannelProvider>
      )}
    </Layout>
  );
};
