import { ReactNode } from "react";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { DangerButton, SecondaryButton } from "@unlikelyai-magic/ui/buttons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors, spacings } from "@unlikelyai-magic/ui/variables";
import { Modal, ModalProps } from "./Modal";
import { useModal } from "./useModal";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  color: ${colors.red["500"]};
  background: ${colors.red["100"]};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Content = styled(Modal.Content)`
  align-items: center;
  text-align: center;
  gap: ${spacings.lg};
`;

type ConfirmationModalProps = ModalProps & {
  title?: string;
  description?: string;
  icon?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
};

export const ConfirmationModal = styled(
  ({
    title = "Are you sure?",
    description = "You will lose your progress.",
    icon = <ExclamationCircleIcon />,
    cancelText = "Cancel",
    confirmText = "Yes, confirm",
    onConfirm,
    className,
  }: ConfirmationModalProps) => {
    const { closeModal } = useModal();

    const handleConfirm = () => {
      onConfirm();
      closeModal();
    };

    return (
      <Modal className={className}>
        <Content>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <Spacing gap="3xs">
            <Heading level="h4">{title}</Heading>
            {description && (
              <Paragraph color={colors.gray["500"]}>{description}</Paragraph>
            )}
          </Spacing>
        </Content>
        <Modal.Footer>
          <SecondaryButton onClick={closeModal}>{cancelText}</SecondaryButton>
          <DangerButton onClick={handleConfirm}>{confirmText}</DangerButton>
        </Modal.Footer>
      </Modal>
    );
  }
)`
  width: 25rem;
  border-radius: 0.75rem;

  & > *:not(:first-child) {
    border-top: none;
  }

  & ${Content}, ${Modal.Footer} {
    padding: ${spacings.xl};
  }

  ${Modal.Footer} {
    gap: ${spacings.xl};
    padding-top: 0;

    & > * {
      flex: 1;
    }
  }
`;
