import { InContextExample } from "@dip/data-access/api-types";
import { theme } from "@dip/theme";

export type Contexts = Record<
  InContextExample,
  { title: string; color: string }
>;

export const contexts: Contexts = {
  [InContextExample.CITIZENSHIP]: {
    title: "Citizenship",
    color: theme.colors.action.primary.inactive,
  },
  // [InContextExample.INSURANCE]: {
  //   title: "Insurance",
  //   color: theme.colors.contexts.insurance,
  // },
  [InContextExample.PERSONAL_JURISDICTION]: {
    title: "Personal jurisdiction",
    color: theme.colors.action.primary[2].inactive,
  },
  [InContextExample.NONE]: {
    title: "None",
    color: theme.colors.semantic.error.default,
  },
};
