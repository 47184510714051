import { Passage } from "../../ul/ul-element";
import { LlPassage, toUlElement } from "../reasoning-engine";
import { StatusCompleted, StatusRunning } from "./status";

export type QueryState = {
  // The unique query id
  queryId: string;
  // The LlPassage natively used in the RE
  query: LlPassage;
  // This is built from the Ll passage - it's not perfect (it abuses the string element type
  // to get things to display) - there's more work to be done to make Ll robust in the FE.
  ulQuery: Passage;
  // As with the above, this is built from a Ll passage but uses the query as a question framing
  // (i.e. query prepended with ((What <unknowns>) <query>) or (IsItTrueThat <query>)
  ulQueryAsQuestion: Passage;
  // Passage from the rule that was applied to generate this query. Will be null if not from a rule
  forRulePassage: Passage | null;
  // The status
  status: QueryStatus;
  // The ids of the solver nodes that invoked this query. Will be
  // empty for the root node, and have a maximum length of 1 until caching
  // is implemented on the backend.
  parentSolverNodeIds: string[];
  // The ids of the solver nodes invoked by this query. There will be one for each solver
  // that returned a `true` for `canSolve` on the BE.
  solverNodeIds: string[];
  // Ids of each solution emitted for this query. Currently, the BE just merges together
  // the solutions from each solver it invokes (i.e. no deduping).
  solutionIds: string[];
};

export type QueryStatus = StatusRunning | StatusCompleted;

export const makeEmptyQueryState = (
  queryId: string,
  query: LlPassage,
  queryAsQuestion: LlPassage,
  forRulePassage: LlPassage | null,
  parentSolverNodeId: string | null,
  startTime: number
): QueryState => {
  return {
    queryId,
    query,
    ulQuery: toUlElement(query) as Passage,
    ulQueryAsQuestion: toUlElement(queryAsQuestion) as Passage,
    forRulePassage: forRulePassage
      ? (toUlElement(forRulePassage) as Passage)
      : null,
    status: { "@type": "RUNNING", startedAt: startTime },
    parentSolverNodeIds: parentSolverNodeId ? [parentSolverNodeId] : [],
    solverNodeIds: [],
    solutionIds: [],
  };
};
