import styled from "@emotion/styled";
import { TextBox, TextBoxWrapper } from "./TextBox";
import { TextBoxContentProps } from "./TextBoxContent";

const TextBoxWrapperOverride = styled(TextBoxWrapper)`
  box-shadow: none;
  padding-top: 0.25rem;
  height: 100%;
  border-radius: 0;
`;

export type NLTextBoxProps = TextBoxContentProps & {
  header?: string;
};

export const NLTextBox = ({
  content,
  header,
  onSelectionChange,
  readOnly,
}: NLTextBoxProps) => (
  <TextBoxWrapperOverride>
    {header && <TextBox.Header>{header}</TextBox.Header>}
    <TextBox.Content
      readOnly={readOnly}
      value={content}
      onSelectionChange={onSelectionChange}
    />
  </TextBoxWrapperOverride>
);
