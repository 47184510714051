import {
  GetReasoningGraphDataRequest,
  GetReasoningGraphDataResponse,
} from "@dip/data-access/api-types";
import { decompressGzipCompressedArray } from "@unlikelyai-magic/core";
import { dipApi } from "./dipApi";

export const { useGetReasoningGraphDataQuery } = dipApi.injectEndpoints({
  endpoints: (builder) => ({
    getReasoningGraphData: builder.query<
      GetReasoningGraphDataResponse,
      GetReasoningGraphDataRequest
    >({
      query: ({ debugLogId }) => ({
        url: `/reasoning/${debugLogId}`,
        headers: {
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip",
        },
        responseHandler: async (response) => {
          const buffer = await response.arrayBuffer();
          return await JSON.parse(decompressGzipCompressedArray(buffer));
        },
      }),
    }),
  }),
});
