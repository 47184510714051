import { useParams } from "react-router-dom";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { PageSpinner } from "@dip/ui/components/spinners";
import { CodeTextBox, NLTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";

const Layout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacings.lg};
  padding: ${({ theme }) => theme.spacings.lg};
  width: 100%;
  @media (min-width: ${screenBreakpoints.md}) {
    grid-template-columns: 0.5fr 1fr;
  }
`;

export const UnderstandingScenarioPage = () => {
  const { domainId, scenarioId } = useParams();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  if (!data) return <PageSpinner />;

  const versions = data.understandings[0].versions;
  const latestVersion = versions?.[versions.length - 1];
  const scenario = data.trainingScenarios.find(({ id }) => id === scenarioId);
  const latestScenarioDecision = latestVersion?.trainingScenarioDecisions.find(
    ({ trainingScenarioId }) => trainingScenarioId === scenarioId
  );
  const question = scenario?.question ?? "";
  const answer = scenario?.solution.answer ?? "";
  const explanation = scenario?.solution.explanation ?? "";
  const questionRepresentation =
    latestScenarioDecision?.decision.questionRepresentation.join("\n\n");

  return (
    <Layout>
      <Spacing>
        <NLTextBox
          key={`${scenarioId}-question`}
          header="Problem"
          content={question}
        />
        <NLTextBox
          key={`${scenarioId}-answer`}
          header="Answer"
          content={answer}
        />
        <NLTextBox
          key={`${scenarioId}-reasoning`}
          header="Reasoning"
          content={explanation}
        />
      </Spacing>
      {questionRepresentation && (
        <CodeTextBox
          key={`${scenarioId}-ul`}
          header="UNIVERSAL LANGUAGE"
          value={questionRepresentation}
        />
      )}
      {/* TODO {UA-254}: Feedback input */}
    </Layout>
  );
};
