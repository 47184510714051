import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Translator from "./translator";

const TranslatorContext = React.createContext<Translator>(
  new Translator("en", "")
);

type TranslatorProps = {
  baseApiPath: string;
  language: string;
  children: React.ReactNode;
};

export const TranslatorContextProvider: FunctionComponent<TranslatorProps> = (
  props
) => {
  const [translator, setTranslator] = useState(
    new Translator("en", props.baseApiPath)
  );

  // Update translator if the language given in props changes
  useEffect(() => {
    setTranslator(new Translator(props.language, props.baseApiPath));
  }, [props.language]);

  return (
    <TranslatorContext.Provider value={translator}>
      {props.children}
    </TranslatorContext.Provider>
  );
};

function useTranslator(): Translator {
  return useContext(TranslatorContext);
}

export { TranslatorContextProvider as default, useTranslator };
