import React, { FunctionComponent } from "react";
import { Tree, Typography } from "antd";
import { UlSerializer } from "../ul/UlSerializer";
import { Explanation, extractExplanationDetails } from "../ul/explanation";
import { UlElement } from "../ul/ul-element";

const { Text } = Typography;

type ExplanationProps = {
  explanation: Explanation;
  /**
   * Optional method to call when explanation elements are clicked.
   */
  onClick?: (elem: UlElement) => void;
};

type TreeNode = {
  key: string;
  details: UlElement;
  children: TreeNode[];
};

/**
 * Shows an explanation as a collapsible tree of passages.
 */
export const DisplayedExplanationTree: FunctionComponent<ExplanationProps> = ({
  explanation,
  onClick,
}) => {
  /**
   * Most explanation types have children. We want to build a tree where such explanations are
   * represented as the passage that's being explained, then, as children, one or more passages that
   * describe the particular explanation. For example, a reasoning explanation has the reasoning
   * passage. A semantic equivalence explanation has the equivalence passages used. Finally, as
   * children of the final passage describing the explanation, the child explanation steps
   * (potentially several for reasoning, always only one for semantic equivalence).
   *
   * Reasoning example:
   * <pre>
   * (IsA Camembert (ClassPlusAttribute Cheese (CombinedAttribute Creamy French)))
   *   (MeansThat (And (IsA X C) (HasAttribute X A)) (IsA X (ClassPlusAttribute C A)))
   *     (HasAttribute Camembert (CombinedAttribute Creamy French))
   *     (IsA Camembert Cheese)
   * </pre>
   */
  function toTreeData(explanation: Explanation, key: string): TreeNode {
    return extractExplanationDetails(
      explanation,
      (passage, details, children) => {
        return {
          key,
          details: passage,
          children: details.map((passage, index1): TreeNode => {
            return {
              key: `${key}-${index1}`,
              details: passage,
              children:
                index1 === details.length - 1
                  ? children.map((child, index2) =>
                      toTreeData(child, `${key}-${index1}-${index2}`)
                    )
                  : [],
            };
          }),
        };
      }
    );
  }

  const displaySerialised = (elem: UlElement) => {
    return (
      <span onClick={() => onClick?.(elem)}>
        <UlSerializer element={elem} truncateUuids={true}>
          {(s) => <Text code>{s}</Text>}
        </UlSerializer>
      </span>
    );
  };

  return (
    <Tree
      // We set a key specific to the explanation so React unmounts and remounts the tree for each
      // explanation, causing defaultExpandAll to fully expand the tree.
      key={JSON.stringify(explanation)}
      defaultExpandAll={true}
      treeData={[toTreeData(explanation, "0")]}
      selectable={false}
      titleRender={(node) => displaySerialised(node.details)}
    />
  );
};
