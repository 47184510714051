import {
  StatusBanner,
  defaultStatusConfig,
} from "@dip/ui/components/status-banner";
import styled from "@emotion/styled";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import {
  DomainStatus,
  ScenarioStatus,
  getDomainStatusInfo,
  getScenarioStatusInfo,
} from "../constants";

const MenuContainer = styled(Spacing)`
  width: 100%;
`;

const Menu = styled(Spacing)`
  padding: 0 ${({ theme }) => theme.spacings.lg};
`;

const DomainMenuItem = styled(Paragraph)<{ selected?: boolean }>`
  padding: ${({ theme }) => theme.spacings.sm}
    ${({ theme }) => theme.spacings.md};
  border-radius: ${({ theme }) => theme.roundness.sm};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.component.background[2] : "transparent"};
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.text.primary.default
      : theme.colors.text.secondary.default};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  :hover {
    cursor: ${({ selected }) => (selected ? "default" : "pointer")};
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.colors.component.background[2]
        : theme.colors.component.background[1]};
  }
`;

const ScenarioMenuItem = styled(Spacing)<{
  selected?: boolean;
  disabled?: boolean;
}>`
  padding: ${({ theme }) => theme.spacings.sm};
  border-radius: ${({ theme }) => theme.roundness.sm};
  padding-left: ${({ theme }) => theme.spacings.sm};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.component.background[2] : "transparent"};
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.text.primary.default
      : theme.colors.text.secondary.default};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  :hover {
    cursor: ${({ selected, disabled }) =>
      disabled ? "not-allowed" : selected ? "default" : "pointer"};
    background-color: ${({ theme, selected, disabled }) =>
      disabled
        ? "transparent"
        : selected
        ? theme.colors.component.background[2]
        : theme.colors.component.background[1]};
  }
`;

interface MenuItem {
  id: string;
  name: string;
  status: ScenarioStatus;
}

export interface DomainStatusMenuProps {
  domainStatus: DomainStatus;
  scenarios: MenuItem[];
  currentScenario?: string;
  setScenario: (scenarioId?: string) => void;
}

export const DomainStatusMenu = ({
  domainStatus,
  scenarios,
  currentScenario,
  setScenario,
}: DomainStatusMenuProps) => {
  const scenariosDisabled =
    domainStatus === DomainStatus.NOT_UNDERSTOOD ||
    domainStatus === DomainStatus.UNDERSTANDING_DOMAIN;

  const scenarioMenuItems = scenarios.map(
    ({ id, name, status: scenarioStatus }) => {
      const { icon, status } = getScenarioStatusInfo(scenarioStatus);
      return {
        id,
        name,
        icon,
        color: defaultStatusConfig[status].color,
      };
    }
  );

  const handleClick = (scenarioName?: string) => {
    if (scenariosDisabled) {
      return;
    }
    setScenario(scenarioName);
  };

  const { status, text } = getDomainStatusInfo(domainStatus);

  return (
    <MenuContainer direction="vertical">
      <StatusBanner status={status} text={text} />
      <Menu gap="none">
        <DomainMenuItem
          small
          onClick={() => handleClick()}
          selected={!currentScenario}
        >
          Domain
        </DomainMenuItem>
        {scenarioMenuItems.map(({ id, name, icon, color }) => (
          <ScenarioMenuItem
            key={id}
            direction="horizontal"
            gap="sm"
            onClick={() => handleClick(id)}
            selected={currentScenario === id}
            disabled={scenariosDisabled}
          >
            <Icon icon={icon} color={color} />
            <Paragraph small>{name}</Paragraph>
          </ScenarioMenuItem>
        ))}
      </Menu>
    </MenuContainer>
  );
};
