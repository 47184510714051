import { ComponentProps } from "react";
import styled from "@emotion/styled";

export const UnlikelyAiLogo = styled((props: ComponentProps<"svg">) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463.84 500" {...props}>
      <g id="Layer_2">
        <g id="Layer_1-2">
          <path
            fill={props.fill ?? "#170f11"}
            d="M163,84.21V44.15L90.94,2.53a18.84,18.84,0,0,0-18.85,0L0,44.15V84.21L25.2,69.66v214.9a94.27,94.27,0,0,0,47.91,82.09l64.73,36.57V69.67Z"
          />
          <path
            fill={props.fill ?? "#170f11"}
            d="M372.9,2.53,300.81,44.15V84.21L326,69.67V403.22l64.73-36.57a94.29,94.29,0,0,0,47.91-82.09V69.66l25.19,14.55V44.15L391.76,2.53A18.86,18.86,0,0,0,372.9,2.53Z"
          />
          <path
            fill={props.fill ?? "#fe4a49"}
            d="M288.24,96.78h0a114.48,114.48,0,0,1-112.64,0h0V430.34l-25.19-14.55v40.06l72.08,41.62a18.86,18.86,0,0,0,18.86,0l72.09-41.62V415.79l-25.2,14.54Z"
          />
        </g>
      </g>
    </svg>
  );
})``;
