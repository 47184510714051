import React, { createContext, useState } from "react";
import { AnswerVM } from "../reasoning-engine/reasoning-engine";
import {
  ExplanationAndIds,
  REExplanationTranslationResponse,
} from "../translation/translator/translator";

interface ReasoningGraphContextExports {
  isGraphOpen: boolean;
  setIsGraphOpen: (isOpen: boolean) => void;
  answer: AnswerVM | undefined;
  setAnswer: (answer: AnswerVM | undefined) => void;
  translatedExplanation: REExplanationTranslationResponse | undefined;
  setTranslatedExplanation: (
    translatedExplanation: REExplanationTranslationResponse | undefined
  ) => void;
  selectedExplanation: ExplanationAndIds | undefined;
  setSelectedExplanation: (
    selectedExplanation: ExplanationAndIds | undefined
  ) => void;
}

export const ReasoningGraphContext =
  createContext<ReasoningGraphContextExports>({
    isGraphOpen: false,
    setIsGraphOpen: () => {},
    answer: undefined,
    setAnswer: () => {},
    translatedExplanation: undefined,
    setTranslatedExplanation: () => {},
    selectedExplanation: { explanation: "", solutionIds: [] },
    setSelectedExplanation: () => {},
  });

interface ReasoningGraphContextProviderProps {
  children: React.ReactNode;
}

export const ReasoningGraphContextProvider = ({
  children,
}: ReasoningGraphContextProviderProps) => {
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const [selectedExplanation, setSelectedExplanation] =
    useState<ExplanationAndIds>();
  const [answer, setAnswer] = useState<AnswerVM>();
  const [translatedExplanation, setTranslatedExplanation] =
    useState<REExplanationTranslationResponse>();

  const handleIsGraphOpen = (isOpen: boolean) => {
    setIsGraphOpen(isOpen);

    if (!isOpen) {
      setSelectedExplanation(undefined);
      setAnswer(undefined);
      setTranslatedExplanation(undefined);
    }
  };

  return (
    <ReasoningGraphContext.Provider
      value={{
        isGraphOpen,
        setIsGraphOpen: handleIsGraphOpen,
        answer,
        setAnswer,
        translatedExplanation,
        setTranslatedExplanation,
        selectedExplanation,
        setSelectedExplanation,
      }}
    >
      {children}
    </ReasoningGraphContext.Provider>
  );
};
