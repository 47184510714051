import {
  ExpertiseType,
  FeedbackRequestedFrom,
  TrainingScenario,
} from "@dip/data-access/api-types";
import {
  DomainEvent,
  EventAction,
  Message,
  useGetDomainsQuery,
} from "@dip/data-access/dip-api-service";
import { theme } from "@dip/theme";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { UnlikelyAISpinner } from "@dip/ui/components/spinners";
import { useDomainChannel } from "@dip/ui/features/domain";
import styled from "@emotion/styled";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Card } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useModal } from "@unlikelyai-magic/ui/modals";
import { Heading } from "@unlikelyai-magic/ui/typography";
import { CreateDomainModal, PolicyRow, PolicyWithStates } from "../components";

const PageWrapper = styled.div`
  padding: 0.62rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled(Spacing)`
  flex: 1;
`;

const PoliciesCard = styled(Card)`
  width: 50%;
  box-shadow: ${({ theme }) => theme.shadows.md};
  border-radius: ${({ theme }) => theme.roundness.md};
  overflow: hidden;
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 90%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-5%);
`;

const LoadingSpinnerContainer = styled.div`
  height: 5rem;
  padding: 1rem;
`;

const AddDomainButton = styled(SecondaryButton)`
  padding: 0.625rem;
  height: 2.5rem;
  font-size: 1rem;
`;

export const Dashboard = () => {
  const { data, isLoading, refetch } = useGetDomainsQuery({
    size: 999,
    sortDescending: true,
  });

  const { openModal } = useModal();

  useDomainChannel((message: Message<DomainEvent>) => {
    switch (message.name) {
      case EventAction.CREATED:
        refetch();
        break;
      case EventAction.UPDATED:
      case EventAction.DELETED: {
        const domainIds = data?.content.map((domain) => domain.id) || [];
        if (domainIds.includes(message.extras?.headers.domainId ?? "")) {
          refetch();
        }
        break;
      }
    }
  });

  const createDomain = () => {
    openModal(<CreateDomainModal />);
  };

  const policies = (data?.content || []).map<PolicyWithStates>((domain) => {
    const record = {
      ...domain,
      loading: false,
      understandingError: false,
      trainingScenariosFailing: false,
    };

    const { understandings } = domain;
    const ulUnderstanding = understandings.find(
      (understanding) => understanding.expertiseType === ExpertiseType.UL
    );

    const versions = ulUnderstanding?.versions;
    if (!versions?.length)
      return {
        ...record,
        loading: true,
      };

    const latestVersion = versions.reduce(
      (latest, current) =>
        new Date(current.dateCreated) > new Date(latest.dateCreated)
          ? current
          : latest,
      versions[0]
    );

    if (latestVersion.feedbackRequestedFrom === FeedbackRequestedFrom.HUMAN)
      return {
        ...record,
        understandingError: true,
      };

    // Determine if the training scenarios which have completed have been answered correctly
    const trainingScenarioProgress = domain.trainingScenarios.map(
      (trainingScenario: TrainingScenario) => {
        const decision = latestVersion.trainingScenarioDecisions?.find(
          ({ trainingScenarioId }) => trainingScenarioId === trainingScenario.id
        );
        return {
          ...trainingScenario,
          decision,
          isWrong:
            decision &&
            decision.decision.result !== trainingScenario.solution.answer,
        };
      }
    );

    // If any training scenario decisions are incorrect, flag it to the user
    const isFailing = trainingScenarioProgress.some(({ isWrong }) => isWrong);
    if (isFailing)
      return {
        ...record,
        trainingScenariosFailing: true,
      };

    return record;
  });

  return (
    <PageWrapper>
      <Spacing direction="horizontal">
        <Icon
          icon={UnlikelyAiLogo}
          fill={theme.colors.text.secondary.default}
          size="1.5rem"
        />
      </Spacing>
      <ContentWrapper justify="center" items="center">
        <LogoContainer>
          <UnlikelyAiLogo
            fill={theme.colors.component.border[1]}
            fillOpacity="40%"
            preserveAspectRatio="none"
            width="100%"
            height="100%"
          />
        </LogoContainer>
        <PoliciesCard
          title={<Heading level="h4">Policies</Heading>}
          extra={
            <AddDomainButton
              icon={<PlusIcon strokeWidth={2} />}
              onClick={createDomain}
            />
          }
          type="inner"
          bodyStyle={{ padding: 0, maxHeight: "20rem", overflowY: "auto" }}
        >
          {isLoading ? (
            <LoadingSpinnerContainer>
              <UnlikelyAISpinner />
            </LoadingSpinnerContainer>
          ) : (
            policies.map((policy) => (
              <PolicyRow key={policy.id} policy={policy} />
            ))
          )}
        </PoliciesCard>
      </ContentWrapper>
    </PageWrapper>
  );
};
