import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "@dip/config";

const DATADOG_APPLICATION_ID = "f5247ba0-8e2b-4198-a581-01b985790c7a";
const DATADOG_CLIENT_TOKEN = "pubbdb004301f4a3bb87a1b49318a6803e0";
const DATADOG_SERVICE = "dip-frontend";
const DATADOG_SITE = "datadoghq.eu";

export const setupDatadog = () => {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: config.ENV,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: config.ENV,
    forwardConsoleLogs: "all",
  });
};
