import { Spinner } from "@dip/ui/components/spinners";
import { Status } from "@dip/ui/components/status-banner";
import {
  CheckIcon,
  ExclamationCircleIcon,
  MinusIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";

export enum DomainStatus {
  SUCCESS = "success",
  NOT_UNDERSTOOD = "not_understood",
  SCENARIOS_FAILING = "scenarios_failing",
  UNDERSTANDING_DOMAIN = "understanding_domain",
  RUNNING_SCENARIOS = "running_scenarios",
}

export enum ScenarioStatus {
  SUCCESS = "success",
  NOT_UNDERSTOOD = "not_understood",
  WRONG_ANSWER = "wrong_answer",
  DISABLED = "disabled",
  RUNNING = "running",
}

export const getDomainStatusInfo = (domainStatus: DomainStatus) => {
  switch (domainStatus) {
    case DomainStatus.SUCCESS:
      return {
        status: Status.SUCCESS,
        text: "All passed",
      };
    case DomainStatus.SCENARIOS_FAILING:
      return {
        status: Status.WARNING,
        text: "Scenarios failed",
      };
    case DomainStatus.NOT_UNDERSTOOD:
      return {
        status: Status.ERROR,
        text: "Domain failed",
      };
    case DomainStatus.UNDERSTANDING_DOMAIN:
      return {
        status: Status.LOADING,
        text: "Understanding domain...",
      };
    case DomainStatus.RUNNING_SCENARIOS:
      return {
        status: Status.LOADING,
        text: "Running scenarios...",
      };
  }
};

export const getScenarioStatusInfo = (scenarioStatus: ScenarioStatus) => {
  switch (scenarioStatus) {
    case ScenarioStatus.SUCCESS:
      return {
        status: Status.SUCCESS,
        icon: CheckIcon,
      };
    case ScenarioStatus.NOT_UNDERSTOOD:
      return {
        status: Status.WARNING,
        icon: ExclamationCircleIcon,
      };
    case ScenarioStatus.WRONG_ANSWER:
      return {
        status: Status.ERROR,
        icon: XMarkIcon,
      };
    case ScenarioStatus.DISABLED:
      return {
        status: Status.DISABLED,
        icon: MinusIcon,
      };
    case ScenarioStatus.RUNNING:
      return {
        status: Status.LOADING,
        icon: Spinner,
      };
  }
};
