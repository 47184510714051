import styled, { CSSObject } from "@emotion/styled";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { modalSectionStyle } from "../styles";

export type ModalContentProps = SpacingProps & {
  scroll?: boolean;
};

export const ModalContent = styled((props: ModalContentProps) => {
  return <Spacing direction="vertical" {...props} />;
})`
  ${modalSectionStyle};
  position: relative;
  flex: 1;

  ${({ scroll }): CSSObject => (scroll ? { overflowY: "auto" } : {})};
`;
