import React from "react";
import { blue } from "@ant-design/colors";
import { Button, Card, List, Space, Typography } from "antd";
import {
  AnswerVM,
  ResponseTopLevelAnswer,
  SolutionMappingItem,
  isIsItTrueThatAnswer,
} from "../../reasoning-engine/reasoning-engine";
import { millisToString } from "../../reasoning-engine/states";
import { UlSerializer } from "../../ul/UlSerializer";

const { Text } = Typography;

const SolutionCardWhat = ({ mapping }: { mapping: SolutionMappingItem[] }) => {
  return (
    <List>
      {mapping.map((item, i) => {
        return (
          <List.Item
            key={i}
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <Text>
              <UlSerializer element={item.first} truncateUuids={true}>
                {(s) => <Text code>{s}</Text>}
              </UlSerializer>

              {" = "}

              <UlSerializer element={item.second} truncateUuids={true}>
                {(s) => <Text code>{s}</Text>}
              </UlSerializer>
            </Text>
          </List.Item>
        );
      })}
    </List>
  );
};

const SolutionCardIsItTrueThat = ({
  outcome,
  mapping,
}: {
  outcome: string;
  mapping: SolutionMappingItem[];
}) => {
  return (
    <>
      <p>
        Is it true? <b>{outcome}</b>
      </p>
      <SolutionCardWhat mapping={mapping} />
    </>
  );
};

export const makeCardInner = (answer: AnswerVM) => {
  if (isIsItTrueThatAnswer(answer)) {
    return (
      <SolutionCardIsItTrueThat
        outcome={answer.outcome}
        mapping={answer.mapping}
      />
    );
  } else {
    return <SolutionCardWhat mapping={answer.mapping} />;
  }
};

const SolutionCard = ({
  topLevelAnswer,
  onExplanationRequested,
  onGraphRequested,
}: {
  topLevelAnswer: ResponseTopLevelAnswer;
  onExplanationRequested: () => void;
  onGraphRequested?: () => void;
}) => {
  const selected = false;

  return (
    <Card
      style={{
        ...(selected
          ? { outline: "1px dashed " + blue[8], margin: "2px" }
          : { margin: "2px" }),
      }}
    >
      {makeCardInner(topLevelAnswer.answer)}
      <Space>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onExplanationRequested();
          }}
        >
          Show explanation
        </Button>
        {onGraphRequested && (
          <Button
            onClick={(event) => {
              event.stopPropagation();
              onGraphRequested();
            }}
          >
            Show Graph
          </Button>
        )}
      </Space>

      {topLevelAnswer.timeTakenMs && (
        <Text
          type="secondary"
          style={{
            position: "absolute",
            right: "5px",
            bottom: "5px",
            fontSize: "12px",
          }}
        >
          {millisToString(topLevelAnswer.timeTakenMs)}
        </Text>
      )}
    </Card>
  );
};

export default SolutionCard;
