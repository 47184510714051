import pako from "pako";

/**
 * Helper function to decompress a gzip compressed array buffer to a string
 * @param array An array of bytes to be decompressed
 * @return String representation of the decompressed byte array
 */
export function decompressGzipCompressedArray(array: ArrayBuffer): string {
  try {
    const decompressedData = pako.inflate(new Uint8Array(array));
    return new TextDecoder("utf-8").decode(decompressedData);
  } catch (err) {
    console.error("Decompression error: ", err);
    throw new Error("Failed to decompress byte array");
  }
}
