import { configureStore } from "@reduxjs/toolkit";
import { dipApi } from "./dipApi";

export const store = configureStore({
  reducer: {
    [dipApi.reducerPath]: dipApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(dipApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
