import { ComponentProps } from "react";
import styled from "@emotion/styled";

export type PromptInputHeaderProps = ComponentProps<"div">;

export const PromptInputHeader = styled((props: PromptInputHeaderProps) => {
  return <div {...props} />;
})`
  background: white;
  border-radius: 1.25rem 1.25rem 0.25rem 0.25rem;
  padding: ${({ theme }) => theme.spacings.lg};

  // Prevent header icons from flex shrinking
  svg {
    flex-shrink: 0;
  }
`;
