import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { message } from "antd";
import {
  AnswerExplanation,
  SolutionMappingItem,
} from "../reasoning-engine/reasoning-engine";
import { UlElement } from "../ul/ul-element";
import {
  TranslationData,
  TranslationDataContext,
} from "./TranslationDataContext";
import { useTranslator } from "./translator/TranslatorContext";
import {
  REExplanationTranslationResponse,
  TranslatorOptions,
} from "./translator/translator";

export type TranslationContextProps = {
  children: React.ReactNode;
  showErrorBanners?: boolean;
};

export type ExplanationAndContext = {
  question: string;
  explanation: AnswerExplanation;
  id: string;
  solutionMapping: SolutionMappingItem[];
};
export const useTranslationData = (
  getNaturalLanguageTranslation: (
    ulElem: UlElement,
    options?: Partial<TranslatorOptions>
  ) => Promise<string[]>,
  getTranslatedExplanation: (
    question: string,
    solutionId: string,
    solutionMapping: SolutionMappingItem[],
    explanation: AnswerExplanation,
    translationOptions: Partial<TranslatorOptions>
  ) => Promise<REExplanationTranslationResponse>,
  showErrorBanners = false
) => {
  const [translationData, setTranslationData] = useState<TranslationData>({
    ulToNatLang: new Map(),
    explanationToTranslation: new Map(),
    notifyUnknownUL: notifyUnknownUL,
    notifyUnknownExplanation: notifyUnknownExplanation,
    clearTranslationCache: clearTranslationCache,
    isTranslationLoading: false,
  });

  const setIsTranslationLoading = (value: boolean) => {
    setTranslationData((prevState) => ({
      ...prevState,
      isTranslationLoading: value,
    }));
  };

  const processingTranslations = useRef(false);
  const elementsBeingProcessed = useRef<Set<UlElement>>(new Set());
  const explanationsProcessed = useRef<Set<AnswerExplanation>>(new Set());
  // Args set by notify functions that are then processed in useEffect
  const [processUnknownUlArgs, setProcessUnknownUlArgs] = useState<UlElement[]>(
    []
  );
  const [processUnknownExplanationArgs, setProcessUnknownExplanationArgs] =
    useState<ExplanationAndContext[]>([]);

  useEffect(() => {
    if (processUnknownUlArgs.length > 0) {
      processUnknownUl(processUnknownUlArgs);
    }
  }, [processUnknownUlArgs]);

  useEffect(() => {
    if (processUnknownExplanationArgs.length > 0) {
      processUnknownExplanation(processUnknownExplanationArgs);
    }
  }, [processUnknownExplanationArgs]);

  const processUnknownUl = (elements: UlElement[]): void => {
    processingTranslations.current = true;

    setIsTranslationLoading(true);

    const promises = elements
      .filter((el) => !elementsBeingProcessed.current.has(el))
      .map(async (el) => {
        elementsBeingProcessed.current.add(el);
        getNaturalLanguageTranslation(el, {
          selectedTranslators: ["nmt-translator"],
        })
          .then((translation) => {
            setTranslationData((prevState) => {
              const newMap = new Map([...prevState.ulToNatLang]); //Create a copy otherwise react won't pick up changes in state
              return {
                ...prevState,
                ulToNatLang: newMap.set(
                  JSON.stringify(el),
                  translation.at(0) ?? "No translation"
                ),
              };
            });
          })
          .catch((err) => {
            showErrorBanners &&
              message.error("Failed to translate UL element: " + err);
            console.error(err);
          });
      });

    Promise.all(promises).finally(() => {
      processingTranslations.current = false;
      setIsTranslationLoading(false);
    });
  };

  const processUnknownExplanation = (
    explanations: ExplanationAndContext[]
  ): void => {
    processingTranslations.current = true;
    setIsTranslationLoading(true);
    explanations
      .filter(
        (explanationAndContext) =>
          !explanationsProcessed.current.has(explanationAndContext.explanation)
      )
      .map((explanationAndContext) => {
        explanationsProcessed.current.add(explanationAndContext.explanation);
        getTranslatedExplanation(
          explanationAndContext.question,
          explanationAndContext.id,
          explanationAndContext.solutionMapping,
          explanationAndContext.explanation,
          {
            selectedTranslators: ["nmt-translator"],
          }
        )
          .then((translation) => {
            setTranslationData((prevState) => {
              const newMap = new Map([...prevState.explanationToTranslation]); //Create a copy otherwise react won't pick up changes in state
              return {
                ...prevState,
                explanationToTranslation: newMap.set(
                  JSON.stringify(explanationAndContext),
                  [
                    ...([
                      {
                        explanation: translation.summarisedTranslation,
                        summarised: true,
                      },
                    ] ?? []),
                    ...([
                      {
                        explanation: translation.fullTranslation,
                        summarised: false,
                      },
                    ] ?? []),
                  ]
                ),
              };
            });
          })
          .catch((err) => {
            showErrorBanners &&
              message.error("Failed to translate explanation: " + err);
            console.error(err);
          })
          .finally(() => {
            // Release the lock
            processingTranslations.current = false;
            setIsTranslationLoading(false);
          });
      });
  };

  function notifyUnknownUL(elements: UlElement[]) {
    // Deduplicate elements before sending to be processed
    const uniqueElements = Array.from(new Set(elements));
    setProcessUnknownUlArgs(uniqueElements);
  }

  function notifyUnknownExplanation(explanations: ExplanationAndContext[]) {
    setProcessUnknownExplanationArgs(explanations);
  }

  function clearTranslationCache() {
    elementsBeingProcessed.current.clear();
    setTranslationData((prevState) => ({
      ...prevState,
      ulToNatLang: new Map(),
    }));
  }

  return {
    translationData,
    notifyUnknownUL,
    notifyUnknownExplanation,
    clearTranslationCache,
    setIsTranslationLoading,
  };
};

export const TranslationContextProvider: FunctionComponent<
  TranslationContextProps
> = (props) => {
  const translator = useTranslator();
  const { translationData } = useTranslationData(
    translator.getNaturalTranslation.bind(translator),
    translator.getSummarisedREExplanationTranslation.bind(translator),
    props.showErrorBanners
  );

  return (
    <TranslationDataContext.Provider value={translationData}>
      {props.children}
    </TranslationDataContext.Provider>
  );
};
