import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { UnlikelyAISpinner } from "./UnlikelyAISpinner";

const SpinnerContainer = styled.div<{ size: number }>`
  width: ${({ size }) => size}rem;
  height: ${({ size }) => size}rem;
`;

/**
 * PageSpinner is a spinner that is centered inside a container that fills the parent.
 */
export const PageSpinner = styled(
  ({ size = 3, ...divProps }: ComponentProps<"div"> & { size?: number }) => (
    <div {...divProps}>
      <SpinnerContainer size={size}>
        <UnlikelyAISpinner />
      </SpinnerContainer>
    </div>
  )
)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
