import React, { Fragment, useContext } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { Alert, Modal, Spin } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { GraphAndExplanation } from ".";
import { ReasoningGraphContext } from "../";
import { AnswerVM } from "../../reasoning-engine/reasoning-engine";
import { FinalQuestionState } from "../../reasoning-engine/states/final-question-state";
import { REExplanationTranslationResponse } from "../../translation/translator/translator";
import { CentredDiv } from "./styles";

const DemoModal = styled(Modal)`
  .ant-modal-body {
    height: calc(100vh - 250px);
    overflow: auto;
    padding: 10px;
  }
`;

type GraphModalProps = {
  finalQuestionState?: FinalQuestionState;
  answer: AnswerVM;
  question?: string;
  isOpen: boolean;
  close: () => void;
  translatedExplanation?: REExplanationTranslationResponse;
  demoStyled: boolean;
  isTranslationLoading?: boolean;
  className?: string;
  showUlInitial?: boolean;
};

export const GraphModal = ({
  finalQuestionState,
  answer,
  question,
  isOpen,
  close,
  translatedExplanation,
  demoStyled,
  isTranslationLoading = false,
  className,
  showUlInitial = false,
}: GraphModalProps) => {
  const { selectedExplanation, setSelectedExplanation } = useContext(
    ReasoningGraphContext
  );

  const ConditionalGraphModalComponents = (
    <>
      {!finalQuestionState ||
      (!translatedExplanation && isTranslationLoading) ? (
        <Spin
          indicator={<LoadingOutlined spin />}
          size={"large"}
          style={{ margin: "auto" }}
        />
      ) : !translatedExplanation && !isTranslationLoading ? (
        <CentredDiv>
          <Alert
            message={"Failed to load translation for this graph"}
            type="error"
            showIcon
          />
        </CentredDiv>
      ) : (
        <GraphAndExplanation
          finalQuestionState={finalQuestionState!}
          answer={answer}
          question={question}
          translatedExplanation={translatedExplanation}
          selectedExplanation={selectedExplanation}
          setSelectedExplanation={setSelectedExplanation}
          showUlInitial={showUlInitial}
        />
      )}
    </>
  );

  return !demoStyled ? (
    <DemoModal
      open={isOpen}
      footer={null}
      onCancel={close}
      cancelButtonProps={{ style: { display: "none" } }}
      width={"80%"}
      className={className}
    >
      {ConditionalGraphModalComponents}
    </DemoModal>
  ) : (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center  h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-slate-50 relative transform overflow-hidden rounded-lg p-4 text-left shadow-xl transition-all w-full h-full m-4">
                <div
                  className="relative w-full h-full"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {ConditionalGraphModalComponents}
                </div>
                <div
                  className="w-7 h-7 absolute top-2 right-2 border rounded-lg cursor-pointer"
                  onClick={close}
                >
                  <Icon icon={XMarkIcon} size="1.5rem" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
