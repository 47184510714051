import { LinkObject } from "react-force-graph-2d";
import { Passage } from "../../ul/ul-element";
import { SourceInformation } from "../SourcedExplanation.types";

export type GraphStructure = {
  queryNodes: Map<string, NodeWithNeighbours>;
  solverNodes: Map<string, NodeWithNeighbours>;
};

export type NodeWithNeighbours = ReasoningEngineBaseNode & {
  childIds: string[];
  parentIds: string[];
};

export const isQuery = (node: NodeWithNeighbours) => {
  return node.entityType === "QUERY";
};

export const isSolver = (node: NodeWithNeighbours) => {
  return !isQuery(node);
};

export type SolverDetails = {
  id: string;
  solutionId: string;
  parentSolutionId?: string;
  executingId?: string;
  ulDescription?: Passage;
  ulConclusion: Passage;
  solverType: NodeType;
  source?: string;
};

export type ReasoningEngineBaseNode = {
  id: string;
  entityType: NodeType;
  naturalLanguage: string | null;
  numSolutions: number;
  ul?: Passage;
  // Below are specific to react force graph
  val?: number;
  color?: string;
  x?: number;
  y?: number;
};

export type NodeSources = {
  solverSource?: string;
  sourceInformation?: SourceInformation[];
};

export interface GraphView {
  nodes: ReasoningEngineBaseNode[];
  links: LinkObject<ReasoningEngineBaseNode>[];
}

export enum NodeType {
  QUERY = "QUERY",
  DEDUCTION = "DEDUCTION",
  AND_SOLVER = "AND_SOLVER",
  OR_SOLVER = "OR_SOLVER",
  KNOWLEDGE = "KNOWLEDGE",
  COMPUTATION = "COMPUTATION",
  OTHER_SOLVER = "OTHER_SOLVER",
}
