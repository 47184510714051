import React, { ComponentProps } from "react";
import styled from "@emotion/styled";

type LoadingIconProps = {
  size?: string | number;
};

type LoadingIconWrapperProps = ComponentProps<"div"> & {
  color?: string;
};

type FadingPathLoadingIconProps = LoadingIconProps & LoadingIconWrapperProps;

const LoadingIconWrapper = styled(
  ({ color, ...props }: LoadingIconWrapperProps) => <div {...props} />
)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
`;

const LoadingIcon = ({ size = "1rem" }: LoadingIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 200 200"
    fill="none"
    color="currentColor"
  >
    <defs>
      <linearGradient id="spinner-secondHalf">
        <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
        <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
      </linearGradient>
      <linearGradient id="spinner-firstHalf">
        <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
        <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
      </linearGradient>
    </defs>

    <g strokeWidth="16">
      <path
        stroke="url(#spinner-secondHalf)"
        d="M 8 100 A 92 92 0 0 1 192 100"
      />
      <path
        stroke="url(#spinner-firstHalf)"
        d="M 192 100 A 92 92 0 0 1 8 100"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M 8 100 A 92 92 0 0 1 8 100"
      />
    </g>

    <animateTransform
      from="0 0 0"
      to="360 0 0"
      attributeName="transform"
      type="rotate"
      repeatCount="indefinite"
      dur="1000ms"
    />
  </svg>
);

export const FadingPathLoadingIcon = ({
  color,
  size,
  ...divProps
}: FadingPathLoadingIconProps) => (
  <LoadingIconWrapper color={color} {...divProps}>
    <LoadingIcon size={size} />
  </LoadingIconWrapper>
);
