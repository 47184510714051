import styled from "@emotion/styled";

const Dot = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  background: ${({ theme }) => theme.colors.text.primary.default};
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
  :nth-of-type(1) {
    animation-delay: 0s;
  }
  :nth-of-type(2) {
    animation-delay: 0.2s;
  }
  :nth-of-type(3) {
    animation-delay: 0.4s;
  }
  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
`;

const DotsContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  height: 1.375rem;
  align-items: center;
`;

export const ChatTyping = () => (
  <DotsContainer>
    <Dot className="typing-dot" />
    <Dot className="typing-dot" />
    <Dot className="typing-dot" />
  </DotsContainer>
);
