import { config } from "@dip/config";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) =>
  fetchBaseQuery({
    baseUrl: config.DIP_API_BASE_URL,
  })(args, api, extraOptions);
