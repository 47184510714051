import styled from "@emotion/styled";
import { LoadingIcon, LoadingIconProps } from "@unlikelyai-magic/ui/icons";

export const Spinner = styled(
  ({ size = "2rem", ...props }: LoadingIconProps) => (
    <LoadingIcon {...props} size={size} />
  )
)`
  path {
    stroke: ${({ theme, color }) =>
      color || theme.colors.action.primary.default};
  }
`;
