export type DefinedNicknamePair = {
  uuid: string;
  nickname: string;
};
export type PseudoNicknamePair = {
  uuid: string;
  pseudoNickname: string;
};

export type Translation = {
  translation: string;
};

export type TranslatedNicknamePair = DefinedNicknamePair & Translation;

export type NicknamePair = DefinedNicknamePair | PseudoNicknamePair;

export function isPseudoNicknamePair(
  pair: NicknamePair
): pair is PseudoNicknamePair {
  return (pair as PseudoNicknamePair).pseudoNickname !== undefined;
}

export function getUsedNickname(pair: NicknamePair): string {
  return isPseudoNicknamePair(pair) ? pair.pseudoNickname : pair.nickname;
}
