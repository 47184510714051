import { useState } from "react";
import Markdown from "react-markdown";
import { UnresolvedNodeDto } from "@dip/data-access/api-types";
import { SecondaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { sleep } from "@unlikelyai-magic/utils";
import { DecisionFeedbackWidget } from "./DecisionFeedbackWidget";

const Container = styled(Spacing)`
  justify-content: flex-start;
  width: 100%;
`;

const Explanation = styled(Markdown)`
  code {
    color: ${({ theme }) => theme.colors.action.dark.hover};
  }
`;

type ExplanationWidgetProps = {
  unresolvedNodes: UnresolvedNodeDto[];
  explanation: string;
  decisionCommentary: string;
  debugLogId?: string;
};

export const ExplanationWidget = ({
  unresolvedNodes,
  explanation,
  decisionCommentary,
  debugLogId,
}: ExplanationWidgetProps) => {
  const { pushMessage } = useChat();
  const [explained, setExplained] = useState(false);

  const showExplanation = async () => {
    setExplained(true);
    pushMessage({
      author: MessageAuthor.User,
      text: "Explain this answer",
    });
    await sleep(500);
    pushMessage({
      author: MessageAuthor.Bot,
      text: <Explanation>{decisionCommentary}</Explanation>,
      widget: (
        <DecisionFeedbackWidget
          unresolvedNodes={unresolvedNodes}
          explanation={explanation}
          debugLogId={debugLogId}
        />
      ),
    });
  };

  return (
    <Container>
      {!explained && (
        <Spacing direction="horizontal" gap="xs">
          {debugLogId && (
            <SecondaryButton onClick={showExplanation}>Explain</SecondaryButton>
          )}
        </Spacing>
      )}
    </Container>
  );
};
