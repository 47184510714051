import React from "react";
import { UlElement } from "../ul/ul-element";
import { ExplanationAndContext } from "./TranslationContextProvider";
import { ExplanationAndIds } from "./translator/translator";

export type TranslationData = {
  ulToNatLang: Map<string, string | undefined>; // JSON stringified UlElement -> natural language
  explanationToTranslation: Map<
    string,
    { explanation: ExplanationAndIds[]; summarised: boolean }[]
  >;
  notifyUnknownUL: (elements: UlElement[]) => void;
  notifyUnknownExplanation: (explanation: ExplanationAndContext[]) => void;
  clearTranslationCache: () => void;
  isTranslationLoading: boolean;
};

export const TranslationDataContext = React.createContext<TranslationData>({
  ulToNatLang: new Map(),
  explanationToTranslation: new Map(),
  notifyUnknownUL: () => {},
  notifyUnknownExplanation: () => {},
  clearTranslationCache: () => {},
  isTranslationLoading: false,
});
