import { Navigate, Route, Routes } from "react-router-dom";
import { RealtimeProvider } from "@dip/data-access/dip-api-service";
import { Dashboard } from "@dip/ui/features/dashboard";
import {
  AnsweringPage,
  DomainLayout,
  DomainProvider,
  UnderstandingDomainPage,
  UnderstandingLayout,
  UnderstandingScenarioPage,
} from "@dip/ui/features/domain";
import { AppLayout } from "@dip/ui/layouts";

export const AppRouter = () => (
  <Routes>
    <Route element={<AppLayout />}>
      <Route element={<RealtimeProvider />}>
        <Route index element={<Dashboard />} />
        <Route path="domains/:domainId" element={<DomainProvider />}>
          <Route element={<DomainLayout />}>
            <Route path="understanding" element={<UnderstandingLayout />}>
              <Route index element={<UnderstandingDomainPage />} />
              <Route
                path="scenarios/:scenarioId"
                element={<UnderstandingScenarioPage />}
              />
            </Route>
            <Route path="answering" element={<AnsweringPage />} />
          </Route>
          <Route index element={<Navigate replace to="answering" />} />
        </Route>
      </Route>
    </Route>
  </Routes>
);
