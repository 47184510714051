import { handleErrors } from "./HttpErrors";

function wait(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

/**
 * Retries the fetch operation a certain number of times with a delay.
 * @param url the URL to fetch
 * @param fetchOptions the fetch options
 * @param retries the number of retry attempts to make. 0 means no retries.
 * @param delayMs the delay in milliseconds between retries
 */
export function fetchRetry(
  url: string,
  fetchOptions = {},
  retries: number,
  delayMs = 1000
): any {
  async function onError(err: any) {
    if (!retries) {
      return Promise.reject(err);
    }
    const triesLeft = retries - 1;
    await wait(delayMs);
    return await fetchRetry(url, fetchOptions, triesLeft, delayMs);
  }

  return fetch(url, fetchOptions).then(handleErrors).catch(onError);
}
