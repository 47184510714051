import { ComponentType, SVGProps } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Dropdown, MenuItemProps } from "antd";
import {
  SecondaryButton,
  SecondaryButtonProps,
} from "@unlikelyai-magic/ui/buttons";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

type ItemLabelProps =
  | {
      text: string;
      icon?: ComponentType<SVGProps<SVGSVGElement>>;
    }
  | {
      text?: string;
      icon: ComponentType<SVGProps<SVGSVGElement>>;
    };

type Action = ItemLabelProps & {
  onClick: MenuItemProps["onClick"];
  danger?: boolean;
  disabled?: boolean;
};

type ActionsMenuProps = SecondaryButtonProps & {
  actions: Action[];
};

const ItemLabel = ({ text, icon }: ItemLabelProps) => (
  <Spacing direction="horizontal" gap="xs">
    {icon && <Icon icon={icon} />}
    {text}
  </Spacing>
);

export const ActionsMenu = ({ actions, ...buttonProps }: ActionsMenuProps) => {
  const items = actions.map(
    ({ onClick, danger, disabled, ...action }, index) => ({
      key: index,
      label: <ItemLabel {...action} />,
      onClick,
      danger,
      disabled,
    })
  );

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <SecondaryButton
        icon={<Icon icon={EllipsisVerticalIcon} size="1.5rem" />}
        onClick={(e) => e.stopPropagation()}
        {...buttonProps}
      />
    </Dropdown>
  );
};
