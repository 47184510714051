import { useContext, useEffect, useState } from "react";
import { useSettingsSidebar } from "@dip/config";
import { UnresolvedNodeDto } from "@dip/data-access/api-types";
import { useGetReasoningGraphDataQuery } from "@dip/data-access/dip-api-service";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { CodeTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import {
  AnswerExplanationBackwardChaining,
  ControlPanel,
  ExplanationCard,
  FinalQuestionState,
  GraphModal,
  NicknameDataContext,
  PreferredTranslation,
  REExplanationTranslationResponse,
  ResponseTopLevelAnswer,
  getFinalState,
  questionStateFromLogs,
  serialiseUlElementLlAware,
  useTranslator,
} from "@unlikelyai-magic/core";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

const Container = styled(Spacing)`
  flex: 1;
  justify-content: flex-start;
`;

const ReasoningGraph = styled(GraphModal)`
  .ant-modal-content {
    border-radius: 1.5rem;

    .ant-modal-body {
      overflow: hidden;
      padding: 1.5rem;
    }

    .ant-layout {
      background: none;
    }

    ${ExplanationCard} {
      top: 1.5rem;
      left: 1.5rem;
      border-radius: 0.75rem;
    }

    ${ControlPanel} {
      display: none;
    }

    canvas {
      background: transparent !important;
    }
  }
`;

type DecisionFeedbackWidgetProps = {
  unresolvedNodes: UnresolvedNodeDto[];
  explanation: string;
  debugLogId?: string;
};

export const DecisionFeedbackWidget = ({
  unresolvedNodes,
  explanation,
  debugLogId,
}: DecisionFeedbackWidgetProps) => {
  const translator = useTranslator();
  const { showAnsweringUL } = useSettingsSidebar();
  const [explaining, setExplaining] = useState<boolean>(false);
  const { data: reasoningGraphData, isFetching: fetchingReasoningGraphData } =
    useGetReasoningGraphDataQuery(
      { debugLogId: debugLogId || "" },
      { skip: !debugLogId || !explaining }
    );
  const [questionState, setQuestionState] = useState<FinalQuestionState>();
  const [explanationTranslation, setExplanationTranslation] =
    useState<REExplanationTranslationResponse>();
  const answer: ResponseTopLevelAnswer = questionState?.topLevelAnswers
    .values()
    .next().value;
  const { uuidToNicknameMap } = useContext(NicknameDataContext);

  useEffect(() => {
    if (!reasoningGraphData) return;

    const questionState = questionStateFromLogs(reasoningGraphData);
    if (!questionState) {
      return;
    }

    const finalState = getFinalState(questionState);
    setQuestionState(finalState);

    const graphAnswer = questionState.topLevelAnswers.values().next().value;
    const explanationAndContext = {
      question: serialiseUlElementLlAware(
        questionState.query,
        uuidToNicknameMap,
        true,
        false
      ),
      explanation: graphAnswer.answer.explanation,
      id: graphAnswer.answer.solutionId,
      solutionMapping: graphAnswer.answer.mapping,
    };

    translator
      .getSummarisedREExplanationTranslation(
        explanationAndContext.question,
        explanationAndContext.id,
        explanationAndContext.solutionMapping,
        explanationAndContext.explanation,
        {
          selectedTranslators: ["nmt-translator"],
          preferredTranslations: unresolvedNodes.map(
            (node): PreferredTranslation => ({
              text: node.resolvedNickname,
              ul_element: {
                uuid: node.nodeId,
              },
            })
          ),
        }
      )
      .then(setExplanationTranslation)
      .catch(console.error);
  }, [reasoningGraphData, translator, uuidToNicknameMap]);

  return (
    <Container>
      {showAnsweringUL && <CodeTextBox header="TRACE" value={explanation} />}
      <Spacing direction="horizontal" gap="xs">
        {debugLogId && (
          <SecondaryButton onClick={() => setExplaining(true)}>
            Graph
          </SecondaryButton>
        )}
      </Spacing>
      {explaining && questionState && (
        <ReasoningGraph
          isOpen
          demoStyled={false}
          finalQuestionState={questionState}
          answer={answer.answer}
          isTranslationLoading={
            fetchingReasoningGraphData || !explanationTranslation
          }
          translatedExplanation={explanationTranslation}
          close={() => setExplaining(false)}
        />
      )}
    </Container>
  );
};
