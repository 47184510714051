import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { DangerButton } from "@unlikelyai-magic/ui/buttons";
import { Heading } from "@unlikelyai-magic/ui/typography";
import { colors } from "@unlikelyai-magic/ui/variables";
import { modalSectionStyle } from "../styles";
import { useModal } from "./useModal";

const CloseButton = styled(DangerButton)`
  color: ${colors.red["500"]};
  background: ${colors.white};
  border: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: ${colors.red["500"]};
    background: ${colors.red["50"]};
  }
`;

export type ModalHeaderProps = ComponentProps<"div"> & {
  title: string;
  onClose?: () => void;
};

export const ModalHeader = styled(
  ({ title, onClose, ...props }: ModalHeaderProps) => {
    const { closeModal } = useModal();
    return (
      <div {...props}>
        <Dialog.Title as={Heading} level="h5">
          {title}
        </Dialog.Title>
        <div>
          <CloseButton icon={<XMarkIcon />} onClick={onClose || closeModal}>
            Cancel
          </CloseButton>
        </div>
      </div>
    );
  }
)`
  ${modalSectionStyle};
  justify-content: space-between;
  align-items: center;
`;
