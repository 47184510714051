import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import ForceGraph2D, {
  ForceGraphMethods,
  ForceGraphProps,
  LinkObject,
} from "react-force-graph-2d";
import { Drawer } from "antd";
import { StepDetails, getStepDetailString } from "./StepDetails";
import {
  GraphNode,
  ReasoningStep,
  StepType,
  isPartOfSolutionPath,
} from "./reasoning-data";

type ReasoningGraphProps = {
  widthPx?: number;
  heightPx?: number;
  reasoningStep: ReasoningStep;
  filterToSolutions?: boolean;

  /**
   * The "generation" to display up until. Any nodes with a generation higher than this will not be
   * included in the graph. See GraphNode::getGeneration in the Java code. Defaults to showing all
   * generations.
   */
  generation?: number;

  /**
   * True to start the force graph simulation from scratch when the data changes. This means all
   * nodes will be positioned at the centre of the graph initially. This is useful if you're
   * changing to a completely different question. If false, attempts to copy across information
   * about nodes from the previous graph, so that position, velocity etc. are maintained. This is
   * useful when moving through the reasoning steps of the same question, because the nodes won't
   * keep "bursting out" from the centre every time.
   */
  resetOnDataChange: boolean;

  /**
   * Optional function that will be called when the data changes with a reference to the force
   * graph's methods. Allows auto-scaling, re-centering etc.
   */
  onDataChange?: (forceGraph: ForceGraphMethods) => void;
};

interface GraphData {
  nodes: NodeObject[];
  links: LinkObject[];
}

const EMPTY_GRAPH: GraphData = {
  nodes: [],
  links: [],
};

type NodeObject = {
  id: string;
  name: string;
  reasoningGraph: GraphNode;
  val: number;
  color: string;
  x?: number;
  y?: number;
};

const DEFAULT_SIZE = 1;
const WITH_SOLUTIONS_SIZE = 5;
const ROOT_SIZE = 10;

const PROCESSING_ROOT_COLOR = "#e88";
const PROCESSED_ROOT_COLOR = "#e00";

const PROCESSING_COLOUR_MAP = new Map();
PROCESSING_COLOUR_MAP.set(StepType.QUERY, "#88e");
PROCESSING_COLOUR_MAP.set(StepType.REASONING, "#8e8");
PROCESSING_COLOUR_MAP.set(StepType.PARTIAL_REASONING, "#8ee");
PROCESSING_COLOUR_MAP.set(StepType.CONNECTOR, "#1e9");
PROCESSING_COLOUR_MAP.set(StepType.YES_NO, PROCESSING_ROOT_COLOR);
PROCESSING_COLOUR_MAP.set(StepType.DONT_KNOW, "#8e8");
PROCESSING_COLOUR_MAP.set(StepType.SEMANTIC_EQUIVALENCE, "#8e8");
PROCESSING_COLOUR_MAP.set(StepType.HYPOTHETICAL, "#8e8");
PROCESSING_COLOUR_MAP.set(StepType.OR, "#8e8");
PROCESSING_COLOUR_MAP.set(StepType.AND, "#8e8");
PROCESSING_COLOUR_MAP.set(StepType.UNSPECIFIED_NODE_CLASS, "#8e8");

const PROCESSED_COLOUR_MAP = new Map();
PROCESSED_COLOUR_MAP.set(StepType.QUERY, "#00e");
PROCESSED_COLOUR_MAP.set(StepType.REASONING, "#0e0");
PROCESSED_COLOUR_MAP.set(StepType.PARTIAL_REASONING, "#0ee");
PROCESSED_COLOUR_MAP.set(StepType.CONNECTOR, "#1e9");
PROCESSED_COLOUR_MAP.set(StepType.YES_NO, PROCESSED_ROOT_COLOR);
PROCESSED_COLOUR_MAP.set(StepType.DONT_KNOW, "#0e0");
PROCESSED_COLOUR_MAP.set(StepType.SEMANTIC_EQUIVALENCE, "#0e0");
PROCESSED_COLOUR_MAP.set(StepType.HYPOTHETICAL, "#0e0");
PROCESSED_COLOUR_MAP.set(StepType.OR, "#0e0");
PROCESSED_COLOUR_MAP.set(StepType.AND, "#0e0");
PROCESSED_COLOUR_MAP.set(StepType.UNSPECIFIED_NODE_CLASS, "#0e0");

/**
 * Displays question processing information as a 2D graph.
 */
export const ReasoningGraph: FunctionComponent<
  ReasoningGraphProps & ForceGraphProps
> = ({
  widthPx,
  heightPx,
  reasoningStep,
  filterToSolutions,
  generation = Number.MAX_SAFE_INTEGER,
  resetOnDataChange,
  onDataChange,
  ...extraProps
}) => {
  const [graphData, setGraphData] = useState<GraphData>(EMPTY_GRAPH);

  const [clickedNode, setClickedNode] = useState<NodeObject | null>();

  const onNodeClick = (node: NodeObject) => {
    setClickedNode(node);
  };

  // Builds a GraphData object from the passed ReasoningGraph. NodeObjects returned in the output
  // GraphData data are reused from prevGraphData where possible.
  function toGraphData(
    reasoningGraph: GraphNode,
    prevGraphData: GraphData,
    spawnPointX?: number,
    spawnPointY?: number,
    id = "root",
    seen: { [id: string]: boolean } = {}
  ): GraphData {
    let color =
      reasoningGraph.state === "Processing"
        ? PROCESSING_COLOUR_MAP.get(reasoningGraph.stepType)
        : PROCESSED_COLOUR_MAP.get(reasoningGraph.stepType);
    if (id === "root") {
      color =
        reasoningGraph.state === "Processing"
          ? PROCESSING_ROOT_COLOR
          : PROCESSED_ROOT_COLOR;
    }

    const newNode: NodeObject = {
      id: id,
      name: getStepDetailString(reasoningGraph),
      reasoningGraph: reasoningGraph,
      val:
        id === "root"
          ? ROOT_SIZE
          : reasoningGraph.stepType === StepType.QUERY &&
            reasoningGraph.numSolutions > 0
          ? WITH_SOLUTIONS_SIZE
          : DEFAULT_SIZE,
      color: color,
      // Spawn new nodes at our given spawn point
      x: spawnPointX,
      y: spawnPointY,
    };

    let childSpawnPointX = spawnPointX;
    let childSpawnPointY = spawnPointY;

    const nodes: NodeObject[] = [];

    // Attempt to reuse the same object for this node by looking for a node object with the same id.
    // Reusing the same object prevents the graph library from recreating the node, allowing the
    // rest of its state (position, velocity etc...) to be maintained.
    const oldNode = prevGraphData.nodes.find((old) => old.id === id);
    if (oldNode) {
      oldNode.id = newNode.id;
      oldNode.val = newNode.val;
      oldNode.name = newNode.name;
      oldNode.reasoningGraph = newNode.reasoningGraph;
      oldNode.color = newNode.color;

      nodes.push(oldNode);

      // If this node already has a position, spawn any future children from that position
      childSpawnPointX = oldNode.x;
      childSpawnPointY = oldNode.y;
    } else {
      nodes.push(newNode);
    }

    const links: LinkObject[] = [];

    reasoningGraph.children
      .filter(filterToSolutions ? isPartOfSolutionPath : () => true)
      .forEach((child) => {
        if (child.generation > generation) {
          return;
        }

        const childId = child.id;

        links.push({
          source: childId,
          target: id,
        });

        if (seen[childId]) {
          // If we've seen this child before, don't recurse and add another node for it. The above
          // will link us to the existing node for this query.
          return;
        }

        seen[childId] = true;
        const { nodes: childNodes, links: childLinks } = toGraphData(
          child,
          prevGraphData,
          childSpawnPointX,
          childSpawnPointY,
          childId,
          seen
        );

        nodes.push(...childNodes);
        links.push(...childLinks);
      });

    return { nodes, links };
  }

  const forceGraphRef = useRef<ForceGraphMethods>();

  useEffect(() => {
    setGraphData((prevGraphData) => {
      return toGraphData(
        reasoningStep.reasoningGraph,
        resetOnDataChange ? EMPTY_GRAPH : prevGraphData
      );
    });
    if (onDataChange && forceGraphRef.current) {
      onDataChange(forceGraphRef.current);
    }
  }, [reasoningStep, generation, filterToSolutions]);

  return (
    <>
      <ForceGraph2D
        ref={forceGraphRef}
        width={widthPx}
        height={heightPx}
        graphData={graphData}
        linkCurvature={0.25}
        linkDirectionalParticles={2}
        linkDirectionalParticleSpeed={0.003}
        onNodeClick={(node) => onNodeClick(node as NodeObject)}
        {...extraProps}
      />
      <Drawer
        open={clickedNode != null}
        onClose={() => setClickedNode(null)}
        mask={false}
      >
        {clickedNode && (
          <StepDetails reasoningGraph={clickedNode.reasoningGraph} />
        )}
      </Drawer>
    </>
  );
};
