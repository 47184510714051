// Wrapper type and methods for handling JCF Optional and VAVR options
// Both forms of optionals are serialised in the same form through our ArrayWrapped serialisers

export const EMPTY_OPTIONAL = [];
export type Optional<T> = T[];

export function getValue<T>(serializedOption: Optional<T>): T | undefined {
  return isPresent(serializedOption) ? serializedOption[0] : undefined;
}

export function isPresent<T>(serialisedOption: Optional<T>): boolean {
  return serialisedOption.length === 1;
}

export function toOptional<T>(value: T | undefined): Optional<T> {
  return value !== undefined ? [value] : EMPTY_OPTIONAL;
}
