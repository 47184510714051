import { useContext } from "react";
import { SettingsSidebarContext } from "../contexts";

export const useSettingsSidebar = () => {
  const context = useContext(SettingsSidebarContext);
  if (!Object.keys(context).length)
    throw new Error(
      "useSettingsSidebar must be used within a SettingsSidebarContext"
    );
  return context;
};
