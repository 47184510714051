import { ComponentProps, useState } from "react";
import { ExpertiseType, InContextExample } from "@dip/data-access/api-types";
import { useCreateDomainMutation } from "@dip/data-access/dip-api-service";
import styled from "@emotion/styled";
import { Form } from "antd";
import { NavigationSteps, StepProps } from "@unlikelyai-magic/ui/menus";
import {
  ConfirmationModal,
  Modal,
  useModal,
} from "@unlikelyai-magic/ui/modals";
import { ExampleRow } from "./components";
import { ContextPage, DomainInputStep, ExamplesStep } from "./steps";

const ModalNavigationContent = styled(Modal.Content)`
  flex: 0;
`;

export const CreateDomainModal = styled(
  ({ className }: ComponentProps<"div">) => {
    const [createDomain] = useCreateDomainMutation();

    const { openModal, closeModal } = useModal();
    const [currentStep, setCurrentStep] = useState(0);

    const [understandingOnboardingForm] = Form.useForm();

    const steps = [
      () => <ContextPage onGoForward={() => setCurrentStep(1)} />,
      () => (
        <DomainInputStep
          onGoBack={() => setCurrentStep(0)}
          onGoForward={() => setCurrentStep(2)}
        />
      ),
      () => <ExamplesStep onGoBack={() => setCurrentStep(1)} />,
    ];

    const handleClose = () => {
      if (currentStep === 0) return closeModal();
      openModal(<ConfirmationModal onConfirm={closeModal} />);
    };

    const Step = steps[currentStep];

    const items: StepProps[] = [
      { title: "Context" },
      { title: "Domain" },
      { title: "Examples" },
    ];

    return (
      <Modal className={className}>
        <Modal.Header title="Create understanding" onClose={handleClose} />
        <ModalNavigationContent>
          <NavigationSteps items={items} current={currentStep} />
        </ModalNavigationContent>
        <Modal.Content scroll>
          <Form
            form={understandingOnboardingForm}
            name="understanding-onboarding"
            initialValues={{ refinements: 5 }}
            onFinish={() => {
              const formValues =
                understandingOnboardingForm.getFieldsValue(true);
              const inContextExampleArray = [
                ...(formValues[
                  `in-context-checkbox-${InContextExample.CITIZENSHIP}`
                ]
                  ? [InContextExample.CITIZENSHIP]
                  : []),
                ...(formValues[
                  `in-context-checkbox-${InContextExample.PERSONAL_JURISDICTION}`
                ]
                  ? [InContextExample.PERSONAL_JURISDICTION]
                  : []),
              ];
              createDomain({
                title: formValues.title,
                content: formValues.domainDescription,
                context: formValues.domainDefinition
                  .toUpperCase()
                  .replace("-", "_") as InContextExample,
                inContextExamples: !inContextExampleArray.length
                  ? [InContextExample.NONE]
                  : inContextExampleArray,
                trainingScenarios: formValues.examples.map(
                  ({ problem, answer, reasoning }: ExampleRow) => ({
                    question: problem,
                    solution: { answer, explanation: reasoning },
                  })
                ),
                understanding: {
                  expertiseType: ExpertiseType.UL,
                  refinements: formValues.refinements,
                },
              });
            }}
          >
            <Step />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
)`
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
`;
