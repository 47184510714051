import { DomainMode } from "@dip/data-access/api-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  PrimaryButtonProps,
  PrimaryButton as UnlikelyPrimaryButton,
} from "@unlikelyai-magic/ui/buttons";

export const PrimaryButton = styled(UnlikelyPrimaryButton)<
  /* TODO: Mode should not be here. Ideally, the theme provider should choose the theme based on the header toggle.
      However, our Figma -> React theme exporting process needs to support this, and we're not yet sure how we want
      to setup multiple themes in Figma.
      See https://linear.app/unlikelyai/issue/UA-361/ensure-figma-react-design-token-export-can-support-multiple-themes */
  PrimaryButtonProps & { mode?: DomainMode }
>`
  ${({ mode = DomainMode.ANSWERING, theme }) => css`
    color: ${theme.colors.text.dark.default};
    background: ${mode === DomainMode.ANSWERING
      ? theme.colors.action.primary.default
      : theme.colors.action.primary[2].default};

    &:hover,
    &:focus,
    &:active,
    &.ant-btn-loading {
      background: ${mode === DomainMode.ANSWERING
        ? theme.colors.action.primary.hover
        : theme.colors.action.primary[2].hover};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.text.dark.default};
      background: ${mode === DomainMode.ANSWERING
        ? theme.colors.action.primary.inactive
        : theme.colors.action.primary[2].inactive};
    }
  `}
`;
