import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import styled from "@emotion/styled";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

const TitleContainer = styled(Spacing)<{ disabled?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.component.border[1]};
  height: 3rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const TableContainer = styled.div<{
  offsetHeader: boolean;
  verticalHeaderBorders?: boolean;
  disabled?: boolean;
}>`
  border-radius: ${({ theme }) => theme.roundness.md};
  box-shadow: ${({ theme }) => theme.shadows.md};
  overflow-y: auto;

  table {
    width: 100%;
    table-layout: fixed;
  }

  thead {
    position: sticky;
    top: ${({ offsetHeader }) => (offsetHeader ? "3rem" : 0)};
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.background.default};
    box-shadow: 0 1px 0 ${({ theme }) => theme.colors.component.border[1]};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  }

  thead tr th {
    padding: 0.75rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: inherit;
    ${({ verticalHeaderBorders, theme }) =>
      verticalHeaderBorders
        ? `border-right: 1px solid ${theme.colors.component.border[1]};`
        : ""}
    &:last-child {
      border-right: none;
    }
  }
`;

const TableBodyWrapper = styled.div<{
  maxContentHeight: string;
  horizontalCellBorders: boolean;
  verticalCellBorders: boolean;
}>`
  max-height: ${({ maxContentHeight }) => maxContentHeight};
  overflow-y: auto;

  table {
    width: 100%;
    table-layout: fixed;
  }

  tbody tr td {
    background-color: ${({ theme }) => theme.colors.background.default};
    padding: 0.5rem 1rem;
    ${({ horizontalCellBorders, theme }) =>
      horizontalCellBorders
        ? `border-bottom: 1px solid ${theme.colors.component.border[1]};`
        : ""}
    ${({ verticalCellBorders, theme }) =>
      verticalCellBorders
        ? `border-right: 1px solid ${theme.colors.component.border[1]};`
        : ""}
    &:last-child {
      border-right: none;
    }
  }

  tbody tr:hover td {
    background-color: ${({ theme }) => theme.colors.background.default};
  }

  tr.disabled-row td {
    opacity: 0.5;
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.background.default};
    border-bottom: 1px solid ${({ theme }) => theme.colors.component.border[1]};
  }
`;

const CellWrapper = styled.div<{
  justifyContent: "flex-start" | "center" | "flex-end";
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const CellContentWrapper = styled.div<{
  justifyContent: "flex-start" | "center" | "flex-end";
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex-shrink: 1;
  width: ${({ justifyContent }) => justifyContent === "flex-start" && "100%"};
`;

export type ColumnProps<T> = ColumnDef<T> & {
  justifyHeaderContent?: "left" | "center" | "right";
  justifyCellContent?: "flex-start" | "center" | "flex-end";
  fixedWidth?: boolean; // if true, this column's width will not change as screen size changes
  flex?: number; // flex units for the flexible columns. If column A has flex 1 and column B has flex 2, column B will remain twice as wide as column A
};

export interface TableProps<T> {
  title?: EmotionJSX.Element;
  columns: ColumnProps<T>[];
  data: T[];
  maxContentHeight?: string;
  horizontalCellBorders?: boolean;
  verticalCellBorders?: boolean;
  verticalHeaderBorders?: boolean;
  isTableDisabled?: boolean;
  onRow?: (record: T) => {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    disabled?: boolean;
  };
}

export const Table = <T extends { disabled?: boolean } | object>({
  title,
  columns,
  data,
  onRow,
  horizontalCellBorders = true,
  verticalCellBorders = false,
  verticalHeaderBorders = false,
  maxContentHeight = "100%",
  isTableDisabled = false,
}: TableProps<T>) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const totalFlexUnits = columns.reduce((sum, col) => sum + (col.flex || 0), 0);
  const colGroup = (
    <colgroup>
      {table.getAllColumns().map((column, index) => {
        const columnDef = column.columnDef as ColumnProps<T>;
        if (columnDef.fixedWidth)
          return (
            <col
              key={index}
              style={{ width: `${column.getSize()}px`, minWidth: "75px" }}
            />
          );
        if (columnDef.flex && totalFlexUnits > 0)
          return (
            <col
              key={index}
              style={{
                width: `${(columnDef.flex / totalFlexUnits) * 100}%`,
                minWidth: "75px",
              }}
            />
          );
        return <col key={index} style={{ width: "auto", minWidth: "75px" }} />;
      })}
    </colgroup>
  );

  return (
    <TableContainer
      offsetHeader={!!title}
      verticalHeaderBorders={verticalHeaderBorders}
      disabled={isTableDisabled}
    >
      {title && (
        <TitleContainer disabled={isTableDisabled}>{title}</TitleContainer>
      )}
      <table>
        {colGroup}
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnDef = header.column.columnDef as ColumnProps<T>;
                return (
                  <th
                    key={header.id}
                    style={{
                      textAlign: columnDef.justifyHeaderContent || "left",
                    }}
                  >
                    {flexRender(columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
      </table>
      <TableBodyWrapper
        maxContentHeight={maxContentHeight}
        horizontalCellBorders={horizontalCellBorders}
        verticalCellBorders={verticalCellBorders}
      >
        <table>
          {colGroup}
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const { disabled = false, ...callbacks } = onRow
                ? onRow(row.original)
                : {};
              return (
                <tr
                  key={row.index}
                  {...callbacks}
                  className={disabled || isTableDisabled ? "disabled-row" : ""}
                >
                  {row.getVisibleCells().map((cell) => {
                    const columnDef = cell.column.columnDef as ColumnProps<T>;
                    return (
                      <td key={cell.id}>
                        <CellWrapper
                          justifyContent={
                            columnDef.justifyCellContent || "flex-start"
                          }
                        >
                          <CellContentWrapper
                            justifyContent={
                              columnDef.justifyCellContent || "flex-start"
                            }
                          >
                            {flexRender(columnDef.cell, cell.getContext())}
                          </CellContentWrapper>
                        </CellWrapper>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableBodyWrapper>
    </TableContainer>
  );
};
