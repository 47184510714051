export enum ENV {
  DEVELOPMENT = "development",
  PROD = "production",
  TEST = "test",
}

export type ApplicationConfig = {
  DIP_API_BASE_URL: string;
  ENV: ENV;
};
