import React, { useContext } from "react";
import { DefinedNicknamePair, NicknamePair } from "./nickname-pair";

export type NicknameData = {
  uuidToNicknameMap: Map<string, NicknamePair | undefined>;
  /**
   * A set of UUIDs with temporary nicknames.
   */
  uuidToTemporaryNickname: Map<string, DefinedNicknamePair>;
  notifyUnknownUuids: (uuids: string[]) => void;
  /**
   * Overwrites the current temporary nicknames in the context to match the ones provided in this method.
   */
  setTemporaryNicknames: (nicknames: Map<string, DefinedNicknamePair>) => void;
  clearNicknameCache: () => void;
};

export const NicknameDataContext = React.createContext<NicknameData>({
  uuidToNicknameMap: new Map(),
  uuidToTemporaryNickname: new Map(),
  notifyUnknownUuids: () => {},
  setTemporaryNicknames: () => {},
  clearNicknameCache: () => {},
});

export function useClearNicknameCache() {
  return useContext(NicknameDataContext).clearNicknameCache;
}
