/* eslint-disable */
// @generated by protobuf-ts 2.9.3 with parameter generate_dependencies,ts_nocheck,eslint_disable,add_pb_suffix
// @generated from protobuf file "ai/unlikely/core/proto/user.proto" (package "ai_unlikely_core_proto", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Uuid } from "./uuid_pb";
/**
 * Defines the user interface.
 * Initially, this is just a name and ID, but in the future, this could be
 * extended to include more information about a user.
 *
 * @generated from protobuf message ai_unlikely_core_proto.User
 */
export interface User {
    /**
     * @generated from protobuf field: ai_unlikely_core_proto.Uuid id = 2;
     */
    id?: Uuid;
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("ai_unlikely_core_proto.User", [
            { no: 2, name: "id", kind: "message", T: () => Uuid },
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "buf.validate.field": { string: { minLen: "1" } } } }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ai_unlikely_core_proto.Uuid id */ 2:
                    message.id = Uuid.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ai_unlikely_core_proto.Uuid id = 2; */
        if (message.id)
            Uuid.internalBinaryWrite(message.id, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ai_unlikely_core_proto.User
 */
export const User = new User$Type();
