import { Optional } from "../Optional";
import { ReasoningEffort } from "../components/ReasoningEffortPicker";
import { DefinedNicknamePair } from "../nicknames/nickname-pair";
import { Explanation } from "../ul/explanation";
import { UlElement, Uuid } from "../ul/ul-element";

export type QuestionProcessorOptions = {
  storeUris: string[];
  reasoningEffort: ReasoningEffort;
  maxResults: number;
  useQueryCache: boolean;
  ignoreCaching: boolean;
  contextPassages: UlElement[];
  outputReasoningDebugLog: boolean;
  recordTrace: boolean;
  addHitCountForQueries: boolean;
  writeThinkingResults: WriteThinkingResults;
  unknownValueLimits: { [name: string]: number };
  shuffleResults: boolean;
  processAndClausesInGivenOrder: boolean;
};

export type Outcome = "Yes" | "No" | "DontKnow";
export type Solution = {
  map: UlElement[][];
};
export type WriteThinkingResults =
  | "DO_NOT_WRITE"
  | "ASYNCHRONOUSLY"
  | "SYNCHRONOUSLY";

export type ExplainedSolution = {
  solution: Solution;
  explanation: Explanation;
};

export enum SolutionCompleteness {
  INCOMPLETE_DUE_TO_LIMIT = "INCOMPLETE_DUE_TO_LIMIT",
  INCOMPLETE_DUE_TO_TIMEOUT = "INCOMPLETE_DUE_TO_TIMEOUT",
  PASSAGE_STORE_COMPLETE = "PASSAGE_STORE_COMPLETE",
  WORLD_COMPLETE = "WORLD_COMPLETE",
}

export function completenessToString(completeness: SolutionCompleteness) {
  switch (completeness) {
    case SolutionCompleteness.INCOMPLETE_DUE_TO_LIMIT:
      return "incomplete due to limit";
    case SolutionCompleteness.INCOMPLETE_DUE_TO_TIMEOUT:
      return "incomplete due to timeout";
    case SolutionCompleteness.PASSAGE_STORE_COMPLETE:
      return "passage-store-complete";
    case SolutionCompleteness.WORLD_COMPLETE:
      return "complete";
  }
}

export const CORE_STORE_URI = "ai.unlikely.core";
export const DEFAULT_STORE_URIS = [
  CORE_STORE_URI,
  "ai.unlikely.debaffling",
  "ai.unlikely.thinkingresults",
  "ai.unlikely.wordnet",
  "ai.unlikely.wordnet-reconcept",
  "ai.unlikely.wikidata",
  "ai.unlikely.wikidata-reconcept",
  "ai.unlikely.prereasoning",
  "ai.unlikely.web-sources.*",
];

export const JOBE_STORES = [
  "ai.unlikely.jobe.main.*",
  "ai.unlikely.jobe.scratch.*",
];
export const JOBE_LE_PLAY_STORE = ["ai.unlikely.jobe.le-play.*"];
export const OPEN_API_STORE = ["ai.unlikely.openapi"];
export const ULTRRA_EXPERIMENTS_STORE = ["ai.unlikely.ultrra-experiments"];
// TODO(PLAT-2811): Make this pull the stores from the backend
export const EXPERIMENTS_STORES = [
  "ai.unlikely.experiments.*",
  "ai.unlikely.experiments.sneese",
];

export const NON_DEFAULT_STORES = [
  ...JOBE_STORES,
  ...JOBE_LE_PLAY_STORE,
  ...OPEN_API_STORE,
  ...ULTRRA_EXPERIMENTS_STORE,
  ...EXPERIMENTS_STORES,
];

export const ALL_STORE_URIS = [...DEFAULT_STORE_URIS, ...NON_DEFAULT_STORES];

export type QuestionProcessorMetadata = {
  debugLogId?: string;
  hitReasoningBudget: boolean;
  solutionsCompleteness: SolutionCompleteness;
  dataDogTraceId?: string;
  coinedNodeNicknames: Map<Uuid, DefinedNicknamePair>;
};

export type QuestionProcessorRawResponse = {
  outcome: Outcome;
  solutions: ExplainedSolution[];
  metadata: {
    debugLogId: Optional<string>;
    hitReasoningBudget: boolean;
    solutionsCompleteness: SolutionCompleteness;
    coinedNodeNicknames: Map<Uuid, DefinedNicknamePair>;
  };
};

export type QuestionProcessorResponse = {
  outcome: Outcome;
  solutions: ExplainedSolution[];
  metadata: QuestionProcessorMetadata;
};

export const QUESTION_PROCESSOR_DEFAULT_OPTIONS: QuestionProcessorOptions = {
  // Store option only has affect for non-default stores, so not necessary to use DEFAULT_STORE_URIS
  storeUris: [],
  reasoningEffort: "LOW",
  maxResults: 100,
  useQueryCache: true,
  contextPassages: [],
  ignoreCaching: false,
  outputReasoningDebugLog: true,
  recordTrace: false,
  addHitCountForQueries: false,
  writeThinkingResults: "DO_NOT_WRITE",
  unknownValueLimits: {},
  shuffleResults: false,
  processAndClausesInGivenOrder: false,
};

export type ReasoningDebugLogInfo = {
  id: string;
  name: string;
  time: number;
  effort: ReasoningEffort;
};

export type ValidateQuestionResult = {
  valid: boolean;
  components: Optional<QuestionComponents>;
};

export type QuestionComponents = {
  outputs: Uuid[];
};
