import { NodeObject } from "react-force-graph-2d";
import { theme } from "@dip/theme";
import { ReasoningEngineBaseNode } from "../reasoning-graph-types";

interface TruncateTextProps {
  ctx: CanvasRenderingContext2D;
  textWords: string[];
  fitWidth: number;
}

const truncateText = ({
  ctx,
  textWords,
  fitWidth,
}: TruncateTextProps): string[] => {
  if (!textWords.length) return [];
  if (fitWidth <= 0) return [textWords.join(" ")];
  for (let idx = 1; idx <= textWords.length; idx++) {
    const str = textWords.slice(0, idx).join(" ");
    if (ctx.measureText(str).width > fitWidth && idx > 1) {
      return [
        textWords.slice(0, idx - 1).join(" "),
        ...truncateText({ ctx, textWords: textWords.slice(idx - 1), fitWidth }),
      ];
    }
  }
  return [textWords.join(" ")];
};

interface AllText {
  natLangText: string;
  sourceText: string[];
  ulText: string;
}

interface TruncateAllTextProps extends AllText {
  globalScale: number;
  debugMode: boolean;
  node: NodeObject<ReasoningEngineBaseNode>;
  ctx: CanvasRenderingContext2D;
}

export const truncateAllText = ({
  natLangText,
  sourceText,
  ulText,
  globalScale,
  debugMode,
  node,
  ctx,
}: TruncateAllTextProps) => {
  const maxLineWidth = 400 / globalScale;
  const allText = debugMode
    ? [
        ulText?.split(" ") ?? [],
        ["Node ID: ", node.id],
        natLangText.split(" "),
        sourceText,
      ]
    : [ulText?.split(" ") ?? []];
  const minimumRowLength = Math.max(
    ...allText.flatMap((row) => row.map((word) => ctx.measureText(word).width))
  );
  const fitWidth = Math.max(maxLineWidth, Math.max(minimumRowLength));

  const truncatedUlText = ulText
    ? truncateText({ ctx, textWords: ulText.split(" "), fitWidth })
    : [];
  const truncatedNodeIdText = truncateText({
    ctx,
    textWords: `Node ID: ${node.id}`.split(" "),
    fitWidth,
  });
  const truncatedNatLangText = truncateText({
    ctx,
    textWords: natLangText.split(" "),
    fitWidth,
  });
  const truncatedSourceText = truncateText({
    ctx,
    textWords: sourceText,
    fitWidth,
  });

  return {
    truncatedUlText,
    truncatedNatLangText,
    truncatedSourceText,
    truncatedNodeIdText,
  };
};

interface CalculateLabelBoxDimensionsProps {
  node: NodeObject<ReasoningEngineBaseNode>;
  ctx: CanvasRenderingContext2D;
  fontSize: number;
  text: Map<string[], string>;
}

/**
 * Calculate the dimensions of the label box for a node.
 * @param node - The node in the graph this label is associated with.
 * @param ctx - The canvas rendering context.
 * @param fontSize - The font size of the text in the label box.
 * @param text - The text to be displayed in the label box. Each key is a different type of information to be displayed, for instance, NL or UL and the corresponding value is the font to the used. Each string inside one of these arrays is a line of text, the number of lines depends on how much truncation is needed.
 */
export const calculateLabelBoxDimensions = ({
  node,
  ctx,
  fontSize,
  text,
}: CalculateLabelBoxDimensionsProps) => {
  const labelStartX = (node.x || 0) + 5;
  const labelStartY = (node.y || 0) + 5;
  const lineHeight = fontSize * 1.5;
  const boxBorder = lineHeight;
  const lineDividers = lineHeight * 0.25;

  const textStartX = labelStartX + boxBorder / 2 + lineHeight + 2;
  const textStartY = labelStartY + boxBorder / 2;

  // Line length depends on the font its going to be written in.
  const maxLineLength = Math.max(
    ...[...text.entries()].map((linesAndFont) => {
      ctx.font = linesAndFont[1];
      return Math.max(
        ...linesAndFont[0].map((t) => ctx.measureText(t ?? "").width)
      );
    })
  );
  const textWidth = maxLineLength + lineHeight + 10;
  //Reset the font
  ctx.font = `${fontSize}px Sans-Serif`;
  const numberLines = [...text.keys()].flatMap((t) => t).length;
  const textHeight = numberLines * lineHeight;

  return {
    labelStartX,
    labelStartY,
    labelWidth: textWidth + boxBorder + lineHeight,
    labelHeight:
      textHeight +
      ([...text.entries()].filter((t) => t[0].length).length - 1) *
        lineDividers + // Remove empty entries and count the number of line dividers needed.
      boxBorder,
    textStartX,
    textStartY,
    lineHeight,
    boxBorder,
  };
};

export const nodeCanvasObject = (
  node: NodeObject<ReasoningEngineBaseNode>,
  ctx: CanvasRenderingContext2D,
  globalScale: number,
  natLangText: string,
  sourceText: string[],
  ulText: string | undefined,
  debugMode: boolean
) => {
  const fontSize = 7 * Math.max(10 / (globalScale * 5), 2);
  ctx.textAlign = "left";
  ctx.textBaseline = "top";
  ctx.lineWidth = 2;
  const codeFont = `${fontSize}px monospace`;
  const defaultFont = `${fontSize}px Sans-Serif`;
  ctx.font = defaultFont;

  const { truncatedUlText, truncatedNatLangText, truncatedSourceText } =
    truncateAllText({
      natLangText,
      sourceText,
      ulText: ulText ?? "",
      globalScale,
      debugMode,
      node,
      ctx,
    });
  //Constants and styling
  const boxDimensions = calculateLabelBoxDimensions({
    node,
    ctx,
    fontSize,
    text: debugMode
      ? new Map([
          [truncatedUlText, codeFont],
          [truncatedNatLangText, defaultFont],
          [truncatedSourceText, defaultFont],
        ])
      : new Map([
          [truncatedNatLangText, defaultFont],
          [truncatedSourceText, defaultFont],
        ]),
  });
  let { textStartY } = boxDimensions;
  const {
    labelStartX,
    labelStartY,
    labelWidth,
    labelHeight,
    lineHeight,
    boxBorder,
    textStartX,
  } = boxDimensions;

  const starsImg = new Image();
  const codeImg = new Image();
  starsImg.src = "data:image/svg+xml;base64," + btoa(starsIcon);
  codeImg.src = "data:image/svg+xml;base64," + btoa(codeSymbol);

  // Draw label
  ctx.beginPath();
  ctx.fillStyle = "rgb(255,255,255)";
  ctx.strokeStyle = "rgb(239,241,241)";
  ctx.strokeRect(labelStartX, labelStartY, labelWidth, labelHeight);
  ctx.fillRect(labelStartX, labelStartY, labelWidth, labelHeight);
  ctx.drawImage(
    starsImg,
    labelStartX + boxBorder / 2,
    textStartY,
    lineHeight,
    lineHeight
  );
  ctx.fillStyle = "rgb(0,0,0)";
  ctx.fillStyle = "rgb(105,104,104)";

  if (truncatedNatLangText.length) {
    writeLines(
      ctx,
      truncatedNatLangText,
      textStartX,
      textStartY + fontSize * 0.25,
      lineHeight
    );
    textStartY =
      textStartY + truncatedNatLangText.length * lineHeight + lineHeight * 0.5;
  }
  if (truncatedSourceText.length) {
    writeLines(ctx, truncatedSourceText, textStartX, textStartY, lineHeight);
    textStartY =
      textStartY + truncatedSourceText.length * lineHeight + lineHeight * 0.5;
  }
  if (debugMode) {
    if (truncatedNatLangText.length) {
      // Style the text like a code block based on code style used in brian chat (dip theme)
      ctx.font = `${fontSize}px monospace`;
      ctx.drawImage(
        codeImg,
        labelStartX + boxBorder / 2,
        textStartY,
        lineHeight,
        lineHeight
      );
      ctx.fillStyle = theme.colors.component.background[1];
      ctx.fillRect(
        textStartX,
        textStartY,
        Math.max(
          ...truncatedUlText.map((t) => ctx.measureText(t ?? "").width)
        ) + fontSize,
        lineHeight * truncatedUlText.length
      );
      ctx.fillStyle = theme.colors.action.dark.hover;
      //ctx.fillStyle = "rgb(105,104,104)";
      writeLines(
        ctx,
        truncatedUlText,
        textStartX + fontSize * 0.5,
        textStartY + fontSize * 0.25,
        lineHeight
      );
    }
  }
};

const writeLines = (
  ctx: CanvasRenderingContext2D,
  lines: string[],
  x: number,
  y: number,
  lineHeight: number
) => {
  for (let idx = 0; idx < lines.length; idx++) {
    ctx.fillText(lines[idx], x, y + idx * lineHeight);
  }
};

const starsIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1196_6354)">
        <path
            d="M6.66626 12.5002C10.7285 12.5002 12.4996 10.791 12.4996 6.66687C12.4996 10.791 14.2583 12.5002 18.3329 12.5002C14.2583 12.5002 12.4996 14.2589 12.4996 18.3335C12.4996 14.2589 10.7285 12.5002 6.66626 12.5002Z"
            stroke="#44403C" stroke-width="1.5" stroke-linejoin="round"/>
        <path
            d="M1.66626 5.41687C4.27773 5.41687 5.41626 4.31815 5.41626 1.66687C5.41626 4.31815 6.54683 5.41687 9.16626 5.41687C6.54683 5.41687 5.41626 6.54744 5.41626 9.16687C5.41626 6.54744 4.27773 5.41687 1.66626 5.41687Z"
            stroke="#44403C" stroke-width="1.5" stroke-linejoin="round"/>
    </g>
    <defs>
        <clipPath id="clip0_1196_6354">
            <rect width="20" height="20" fill="white"/>
        </clipPath>
    </defs>
</svg>`;

const codeSymbol = `<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 101.57" style="enable-background:new 0 0 122.88 101.57" xml:space="preserve"><g><path fill="#44403C" stroke="#44403C" d="M44.97,12.84h-17.2L0,49.37L27.77,85.9h17.2L17.2,49.37L44.97,12.84L44.97,12.84z M77.91,12.84h17.2l27.77,36.53 L95.11,85.9h-17.2l27.77-36.53L77.91,12.84L77.91,12.84z M70.17,0.04l5.96,1.39c0.94,0.22,1.52,1.16,1.31,2.1l-22.5,96.69 c-0.22,0.93-1.16,1.52-2.1,1.31l-5.95-1.39c-0.94-0.22-1.52-1.16-1.31-2.1l22.5-96.69C68.3,0.42,69.24-0.17,70.17,0.04L70.17,0.04 L70.17,0.04z"/></g></svg>`;
