import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config, useSettingsSidebar } from "@dip/config";
import { ExpertiseType } from "@dip/data-access/api-types";
import {
  useCreateDomainDecisionMutation,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { PromptInput } from "@dip/ui/components/inputs";
import { PageSpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import {
  TranslatorContextProvider,
  UlSerializerContext,
} from "@unlikelyai-magic/core";
import { MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { ChatContainer, ExplanationWidget } from "./components";
import { chatContainerStyle } from "./styles";
import { DomainExpertState } from "./types";

const ChatPageContainer = styled.div`
  ${chatContainerStyle};
  height: 100%;
  padding: ${({ theme }) => theme.spacings.xl} 0;
`;

const MessageInput = styled(PromptInput)`
  ${chatContainerStyle};
`;

const FloatingInput = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spacings["2xl"]};
  left: 0;
  right: 0;
`;

const ChatBox = styled(ChatContainer)`
  width: 100%;
  height: 100%;
  padding-bottom: 7rem; // Spacing so the content is not under the message input
`;

export const AnsweringPage = () => {
  const { domainId } = useParams();
  const { numOfRefinements } = useSettingsSidebar();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );
  const messagesState = useChat();
  const { pushMessage, updateMessage } = messagesState;
  const [createDecisions] = useCreateDomainDecisionMutation();
  const domainUnderstanding = data?.understandings[0];
  const versions = domainUnderstanding?.versions;
  const latestVersion = versions ? versions[versions.length - 1] : null;
  const [domainExpertState, setDomainExpertState] =
    useState<DomainExpertState>();

  useEffect(() => {
    if (!latestVersion) return;
    setDomainExpertState({
      expertContext: {
        expertiseType: ExpertiseType.UL,
        representation: latestVersion.representation,
        messageHistory: [],
        feedback: [],
      },
      understandingVersion: latestVersion,
    });
  }, [latestVersion]);

  const handleSendUserMessage = async (text: string) => {
    if (!data) return;
    if (!domainUnderstanding) return;
    if (!latestVersion) return;

    const normalisedText = text.trim();
    if (!normalisedText) return;
    pushMessage({
      author: MessageAuthor.User,
      text: normalisedText,
    });

    const botMessageId = pushMessage({
      author: MessageAuthor.Bot,
      text: "",
    });

    try {
      const result = await createDecisions({
        domainId: data.id,
        domainUnderstandingId: domainUnderstanding.id,
        versionId: latestVersion.id,
        decisions: [
          {
            question: normalisedText,
            refinements: numOfRefinements,
          },
        ],
      }).unwrap();

      const { decision } = result.data[0];
      const mergedUnresolvedNodes = [
        ...(latestVersion.unresolvedNodes || []),
        ...(decision.unresolvedNodes || []),
      ];
      updateMessage({
        id: botMessageId,
        text: decision.result ?? "I'm not sure",
        widget: decision.explanation ? (
          <ExplanationWidget
            unresolvedNodes={mergedUnresolvedNodes}
            explanation={decision.explanation}
            decisionCommentary={decision.decisionCommentary}
            debugLogId={decision.debugInfo}
          />
        ) : null,
      });
    } catch (err) {
      updateMessage({
        id: botMessageId,
        text: "Something went wrong, please try again",
      });
    }
  };

  if (!data || !domainExpertState?.understandingVersion) {
    return <PageSpinner />;
  }

  return (
    <ChatPageContainer>
      <UlSerializerContext>
        <TranslatorContextProvider
          language="en"
          baseApiPath={config.DIP_API_BASE_URL}
        >
          <ChatBox domainName={data.title} />
          <FloatingInput>
            <MessageInput
              placeholder="Ask a question..."
              onSubmit={handleSendUserMessage}
            />
          </FloatingInput>
        </TranslatorContextProvider>
      </UlSerializerContext>
    </ChatPageContainer>
  );
};
