/* eslint-disable */
// @generated by protobuf-ts 2.9.3 with parameter generate_dependencies,ts_nocheck,eslint_disable,add_pb_suffix
// @generated from protobuf file "ai/unlikely/core/proto/uuid.proto" (package "ai_unlikely_core_proto", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * TODO(LIT-277): require the msb and lsb values to be non-zero
 *
 * @generated from protobuf message ai_unlikely_core_proto.UuidMostLeastSignificantBits
 */
export interface UuidMostLeastSignificantBits {
    /**
     * @generated from protobuf field: sfixed64 most_significant_bits = 1;
     */
    mostSignificantBits: bigint;
    /**
     * @generated from protobuf field: sfixed64 least_significant_bits = 2;
     */
    leastSignificantBits: bigint;
}
/**
 * @generated from protobuf message ai_unlikely_core_proto.UuidString
 */
export interface UuidString {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
}
/**
 * A UUID, defined using two 64-bit integers for the least and most significant bits.
 *
 * @generated from protobuf message ai_unlikely_core_proto.Uuid
 */
export interface Uuid {
    /**
     * @generated from protobuf oneof: representation
     */
    representation: {
        oneofKind: "mostLeastSignificantBits";
        /**
         * @generated from protobuf field: ai_unlikely_core_proto.UuidMostLeastSignificantBits most_least_significant_bits = 1;
         */
        mostLeastSignificantBits: UuidMostLeastSignificantBits;
    } | {
        oneofKind: "string";
        /**
         * @generated from protobuf field: ai_unlikely_core_proto.UuidString string = 2;
         */
        string: UuidString;
    } | {
        oneofKind: undefined;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class UuidMostLeastSignificantBits$Type extends MessageType<UuidMostLeastSignificantBits> {
    constructor() {
        super("ai_unlikely_core_proto.UuidMostLeastSignificantBits", [
            { no: 1, name: "most_significant_bits", kind: "scalar", T: 16 /*ScalarType.SFIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "least_significant_bits", kind: "scalar", T: 16 /*ScalarType.SFIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<UuidMostLeastSignificantBits>): UuidMostLeastSignificantBits {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mostSignificantBits = 0n;
        message.leastSignificantBits = 0n;
        if (value !== undefined)
            reflectionMergePartial<UuidMostLeastSignificantBits>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UuidMostLeastSignificantBits): UuidMostLeastSignificantBits {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sfixed64 most_significant_bits */ 1:
                    message.mostSignificantBits = reader.sfixed64().toBigInt();
                    break;
                case /* sfixed64 least_significant_bits */ 2:
                    message.leastSignificantBits = reader.sfixed64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UuidMostLeastSignificantBits, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sfixed64 most_significant_bits = 1; */
        if (message.mostSignificantBits !== 0n)
            writer.tag(1, WireType.Bit64).sfixed64(message.mostSignificantBits);
        /* sfixed64 least_significant_bits = 2; */
        if (message.leastSignificantBits !== 0n)
            writer.tag(2, WireType.Bit64).sfixed64(message.leastSignificantBits);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ai_unlikely_core_proto.UuidMostLeastSignificantBits
 */
export const UuidMostLeastSignificantBits = new UuidMostLeastSignificantBits$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UuidString$Type extends MessageType<UuidString> {
    constructor() {
        super("ai_unlikely_core_proto.UuidString", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "buf.validate.field": { string: { uuid: true } } } }
        ]);
    }
    create(value?: PartialMessage<UuidString>): UuidString {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<UuidString>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UuidString): UuidString {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UuidString, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ai_unlikely_core_proto.UuidString
 */
export const UuidString = new UuidString$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Uuid$Type extends MessageType<Uuid> {
    constructor() {
        super("ai_unlikely_core_proto.Uuid", [
            { no: 1, name: "most_least_significant_bits", kind: "message", oneof: "representation", T: () => UuidMostLeastSignificantBits },
            { no: 2, name: "string", kind: "message", oneof: "representation", T: () => UuidString }
        ]);
    }
    create(value?: PartialMessage<Uuid>): Uuid {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.representation = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<Uuid>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Uuid): Uuid {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ai_unlikely_core_proto.UuidMostLeastSignificantBits most_least_significant_bits */ 1:
                    message.representation = {
                        oneofKind: "mostLeastSignificantBits",
                        mostLeastSignificantBits: UuidMostLeastSignificantBits.internalBinaryRead(reader, reader.uint32(), options, (message.representation as any).mostLeastSignificantBits)
                    };
                    break;
                case /* ai_unlikely_core_proto.UuidString string */ 2:
                    message.representation = {
                        oneofKind: "string",
                        string: UuidString.internalBinaryRead(reader, reader.uint32(), options, (message.representation as any).string)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Uuid, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ai_unlikely_core_proto.UuidMostLeastSignificantBits most_least_significant_bits = 1; */
        if (message.representation.oneofKind === "mostLeastSignificantBits")
            UuidMostLeastSignificantBits.internalBinaryWrite(message.representation.mostLeastSignificantBits, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ai_unlikely_core_proto.UuidString string = 2; */
        if (message.representation.oneofKind === "string")
            UuidString.internalBinaryWrite(message.representation.string, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ai_unlikely_core_proto.Uuid
 */
export const Uuid = new Uuid$Type();
