import {
  CreateFeedbackRequest,
  CreateFeedbackResponse,
} from "@dip/data-access/api-types";
import { dipApi } from "./dipApi";

export const { useCreateFeedbackMutation } = dipApi.injectEndpoints({
  endpoints: (builder) => ({
    createFeedback: builder.mutation<
      CreateFeedbackResponse,
      CreateFeedbackRequest
    >({
      query: ({ domainId, domainUnderstandingId, ...body }) => ({
        url: `/domains/${domainId}/understandings/${domainUnderstandingId}/feedback`,
        method: "POST",
        body,
      }),
    }),
  }),
});
