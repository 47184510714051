import React from "react";
import styled from "@emotion/styled";
import {
  Steps as AntDSteps,
  StepsProps as AntDStepsProps,
  StepProps as AntdStepProps,
} from "antd";

export type StepProps = AntdStepProps;
export type StepsProps = AntDStepsProps;

export const Steps = styled((props: StepsProps) => (
  <AntDSteps type="navigation" {...props} />
))`
  width: fit-content;
`;
